import React, { ReactElement } from 'react';
import { Modal } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      paddingTop: theme.spacing(8),
      width: '100%',
      visibility: 'visible',
      zIndex: 9999,
    },
    progress: {
      height: '2em',
      outline: 'none',
    },
  });

const Loading = ({ classes }: WithStyles<typeof styles>): ReactElement => {
  return (
    <Modal open={true} className={classes.loading}>
      <CircularProgress className={classes.progress} />
    </Modal>
  );
};

export default withStyles(styles)(Loading);
