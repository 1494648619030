import React, { ReactElement, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Divider,
  Grid,
  Paper,
  StyleRules,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../../actions/consultant/consultantVerificationActions';
import { EditInformationJumpDialog } from '../../../components/Dialog';
import { AppState } from '../../../reducers';
import { consultantPrevStateAction } from '../../../reducers/consultantReducer';
import { showNotificationAction } from '../../../reducers/notificationReducer';
import { activeConsultantVerificationStep } from '../../../reducers/otherStatus';
import { createConsultantSettingsInfo } from '../consultant.model';
import SetInputView, { ConsultantSettingsInfoControl } from './SetingsInputView';
import ConsultantStepBar from './VerificationStepperView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    formContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    submit: {
      textAlign: 'center',
    },
    infoTitle: {
      marginBottom: theme.spacing(1),
    },
    form: {
      width: '100%',
    },
    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      justifyContent: 'end',
    },
    ButtonMargin: {
      margin: '0 0.25rem',
    },
    bothButton: {
      padding: '2rem 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

const WorkExperienceView = ({ classes }: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('SetingsView');
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const [consultantType, setConsultantType] = useState<string>(
    consultant.verification?.consultantType || 'PERSONAL'
  );
  const verificationStatus = consultant.verification?.status;
  setConsultantType;
  const consultantId = consultant.id;
  const verificationId = consultant?.verification?.id;

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      settings: createConsultantSettingsInfo(),
    },
    mode: 'onChange',
  });
  const { errors, isValid, isDirty } = useFormState({ control });
  const [isDialogOpenJump, setIsDialogOpenJump] = useState<boolean>(false);
  const [statusConst, setStatusConst] = useState<consultantRegisterStepType>();

  const EditInformationDialog = async (): Promise<void> => {
    if (isValid) {
      const settingsData = JSON.stringify(getValues('settings'));
      const settingsNewData = JSON.parse(settingsData);
      settingsNewData.workScenario = JSON.stringify(settingsNewData.workScenario);
      if (verificationStatus == 'SUBMITTED' || verificationStatus == 'VERIFIED') {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            settings: settingsNewData,
          })
        );
      } else {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            settings: settingsNewData,
            status: 'SETTINGS_REGISTERED',
          })
        );
      }
      setStatusConst(prevCount => {
        const stateStep = prevCount;
        dispatch(
          activeConsultantVerificationStep({
            ConsultantVerificationStep: stateStep,
          })
        );
        return prevCount;
      });
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('settings_view:data_not'),
        })
      );
    }
    setIsDialogOpenJump(false);
  };

  const CancelSubmission = async (): Promise<void> => {
    dispatch(
      consultantPrevStateAction({
        id: verificationId,
        consultantId,
        basicInfo: consultant.verification?.basicInfo,
        certificateInfoList: consultant.verification?.certificateInfoList,
        companyResourceInfoList: consultant.verification?.companyResourceInfoList,
        educationInfoList: consultant.verification?.educationInfoList,
        honorInfoList: consultant.verification?.honorInfoList,
        languageInfoList: consultant.verification?.languageInfoList,
        projectInfoList: consultant.verification?.projectInfoList,
        workInfoList: consultant.verification?.workInfoList,
        settings: consultant.verification?.settings,
        otherInfo: consultant.verification?.otherInfo,
        consultantType: 'PERSONAL',
        status: consultant.verification?.status,
      })
    );

    setIsDialogOpenJump(false);
    setStatusConst(prevCount => {
      const stateStep = prevCount;
      dispatch(
        activeConsultantVerificationStep({
          ConsultantVerificationStep: stateStep,
        })
      );
      return prevCount;
    });
  };

  const handleVerificationPrevious = async (): Promise<void> => {
    setStatusConst('PROJECT_STEP');
    isSubmit();
    statusConst;
  };

  const handleVerificationUpdate = async (): Promise<void> => {
    setStatusConst('SETTINGS_STEP');
    isSubmit();
  };

  const isSubmit = async (statusTypeStatus?: consultantRegisterStepType): Promise<void> => {
    if (statusTypeStatus) {
      setStatusConst(statusTypeStatus);
    }
    if (isDirty) {
      trigger('settings');
      setIsDialogOpenJump(true);
    } else {
      if (consultant.verification?.settings) {
        dispatch(
          consultantPrevStateAction({
            id: verificationId,
            consultantId,
            basicInfo: consultant.verification?.basicInfo,
            certificateInfoList: consultant.verification?.certificateInfoList,
            companyResourceInfoList: consultant.verification?.companyResourceInfoList,
            educationInfoList: consultant.verification?.educationInfoList,
            honorInfoList: consultant.verification?.honorInfoList,
            languageInfoList: consultant.verification?.languageInfoList,
            projectInfoList: consultant.verification?.projectInfoList,
            workInfoList: consultant.verification?.workInfoList,
            settings: consultant.verification?.settings,
            consultantType: 'PERSONAL',
            otherInfo: consultant.verification?.otherInfo,
            status: consultant.verification?.status,
          })
        );
        setStatusConst(prevCount => {
          const stateStep = prevCount;
          dispatch(
            activeConsultantVerificationStep({
              ConsultantVerificationStep: stateStep,
            })
          );
          return prevCount;
        });
      } else {
        setIsDialogOpenJump(true);
      }
    }
  };

  useEffect(() => {
    if (consultant.verification && consultant.verification.settings) {
      if (typeof consultant.verification.settings.workScenario == 'string') {
        const setData = consultant.verification.settings;
        const workScenario = JSON.parse(JSON.stringify(setData.workScenario));
        setData.workScenario = workScenario;
        setValue('settings', setData);
      } else {
        setValue('settings', consultant.verification.settings);
      }
    }
  }, [consultant.verification?.settings]);

  return (
    <>
      {consultantType === 'PERSONAL' && (
        <ConsultantStepBar handleStepUpdate={isSubmit}></ConsultantStepBar>
      )}
      <Grid id="basicView" item xs={12}>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('settings_view:other_settings')}
            </Typography>
          </Grid>
          <Divider />

          <Grid container spacing={2}>
            <SetInputView
              control={(control as unknown) as ConsultantSettingsInfoControl}
              errors={errors}
            />
          </Grid>

          <Grid item container xs={12} className={classes.bothButton}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleVerificationPrevious}
            >
              <SkipPreviousIcon fontSize="small" />
              {t('settings_view:back')}
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              // disabled={!(isDirty && !!dirtyFields.basicInfo)}
              onClick={(): void => {
                handleVerificationUpdate();
              }}
            >
              {verificationStatus == 'SUBMITTED' || verificationStatus == 'VERIFIED'
                ? '下一步'
                : t('settings_view:next_step')}
              <SkipNextIcon fontSize="small" />
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <EditInformationJumpDialog
        isOpen={isDialogOpenJump}
        handleEdit={() => {
          EditInformationDialog();
        }}
        handleDialogClose={() => {
          CancelSubmission();
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  consultant?: Consultant;
}

export default withStyles(styles)(WorkExperienceView);
