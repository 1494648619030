import React, { ReactElement, useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Divider,
  Grid,
  Paper,
  StyleRules,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

import { saveConsultantVerification } from '../../../actions/consultant/consultantVerificationActions';
import { EditInformationJumpDialog } from '../../../components/Dialog';
import { AppState } from '../../../reducers';
import { consultantPrevStateAction } from '../../../reducers/consultantReducer';
import { showNotificationAction } from '../../../reducers/notificationReducer';
import { activeConsultantVerificationStep } from '../../../reducers/otherStatus';
import {
  createConsultantEducationInfo,
  createConsultantProjectInfo,
  createConsultantWorkInfo,
} from '../consultant.model';
import EducationInputView, { ConsultantEducationInfoControl } from './EducationInputView';
import ProjectExperienceInputView, {
  ConsultantProjectInfoControl,
} from './ProjectExperiencesInputView';
import ConsultantStepBar from './VerificationStepperView';
import WorkInputView, { ConsultantWorkInfoControl } from './WorkInputView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    formContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    submit: {
      textAlign: 'center',
    },
    infoTitle: {
      marginBottom: theme.spacing(1),
    },
    form: {
      width: '100%',
    },
    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      justifyContent: 'end',
    },
    ButtonMargin: {
      margin: '0 auto',
    },
    bothButton: {
      padding: '2rem 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

const WorkExperienceView = ({ classes }: Props): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const consultantId = consultant.id;
  const verificationId = consultant?.verification?.id;
  const [consultantType, setConsultantType] = useState<string>(
    consultant.verification?.consultantType || 'PERSONAL'
  );
  const verificationStatus = consultant.verification?.status;
  setConsultantType;

  const { setValue, control, getValues, trigger } = useForm({
    defaultValues: {
      workInfoList: [createConsultantWorkInfo()],
      educationInfoList: [createConsultantEducationInfo()],
      projectInfoList: [createConsultantProjectInfo()],
    },
    mode: 'onChange',
  });

  const { errors, isValid, isDirty } = useFormState({ control });

  const {
    fields: workInfoList,
    append: appendWorkInfoList,
    remove: removeWorkInfoList,
  } = useFieldArray({
    control,
    name: 'workInfoList',
  });

  const handleWorkInfoAdd = () => {
    appendWorkInfoList(createConsultantWorkInfo());
  };

  const handleWorkInfoDelete = (index?: number | number[]) => {
    removeWorkInfoList(index);
  };

  const {
    fields: educationInfoList,
    append: appendEducationInfoList,
    remove: removeEducationInfoList,
  } = useFieldArray({
    control,
    name: 'educationInfoList',
  });

  const handleEducationInfoDelete = (index?: number | number[]) => {
    removeEducationInfoList(index);
  };

  const handleEducationInfoAdd = () => {
    appendEducationInfoList(createConsultantEducationInfo());
  };

  const {
    fields: projectInfoList,
    append: appendProjectInfoList,
    remove: removeProjectInfoList,
  } = useFieldArray({
    control,
    name: 'projectInfoList',
  });

  const handleProjectExperienceAdd = () => {
    appendProjectInfoList(createConsultantProjectInfo());
  };

  const handleProjectExperienceDelete = (index?: number | number[]) => {
    removeProjectInfoList(index);
  };

  const [isDialogOpenJump, setIsDialogOpenJump] = useState<boolean>(false);
  const [statusConst, setStatusConst] = useState<consultantRegisterStepType>();
  statusConst;
  const EditInformationDialog = async (): Promise<void> => {
    if (isValid) {
      const workInfoData = JSON.stringify(getValues('workInfoList'));
      const workData = JSON.parse(workInfoData);
      workData.forEach((workInfo: ConsultantWorkInfo) => {
        if (typeof workInfo.professionalClass != 'string')
          workInfo.professionalClass = JSON.stringify(workInfo.professionalClass);
        if (typeof workInfo.industryClass != 'string')
          workInfo.industryClass = JSON.stringify(workInfo.industryClass);
      });

      getValues('projectInfoList').forEach((itemInfo, index) => {
        if (itemInfo.projectDateStart)
          setValue(
            `projectInfoList.${index}.projectDateStart`,
            new Date(itemInfo.projectDateStart)
          );
        if (itemInfo.projectDateEnd)
          setValue(`projectInfoList.${index}.projectDateEnd`, new Date(itemInfo.projectDateEnd));
      });

      if (verificationStatus == 'SUBMITTED' || verificationStatus == 'VERIFIED') {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            workInfoList: workData,
            educationInfoList: getValues('educationInfoList'),
            projectInfoList: getValues('projectInfoList'),
          })
        );
      } else {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            workInfoList: workData,
            educationInfoList: getValues('educationInfoList'),
            projectInfoList: getValues('projectInfoList'),
            status: 'WORK_REGISTERED',
          })
        );
      }

      setStatusConst(prevCount => {
        const stateStep = prevCount;
        dispatch(
          activeConsultantVerificationStep({
            ConsultantVerificationStep: stateStep,
          })
        );
        return prevCount;
      });
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('work_view:data_not'),
        })
      );
      return;
    }

    setIsDialogOpenJump(false);
  };

  const CancelSubmission = async (): Promise<void> => {
    dispatch(
      consultantPrevStateAction({
        id: verificationId,
        consultantId,
        basicInfo: consultant.verification?.basicInfo,
        certificateInfoList: consultant.verification?.certificateInfoList,
        companyResourceInfoList: consultant.verification?.companyResourceInfoList,
        educationInfoList: consultant.verification?.educationInfoList,
        honorInfoList: consultant.verification?.honorInfoList,
        languageInfoList: consultant.verification?.languageInfoList,
        projectInfoList: consultant.verification?.projectInfoList,
        workInfoList: consultant.verification?.workInfoList,
        settings: consultant.verification?.settings,
        otherInfo: consultant.verification?.otherInfo,
        consultantType: 'PERSONAL',
        status: consultant.verification?.status,
      })
    );

    setIsDialogOpenJump(false);
    setStatusConst(prevCount => {
      const stateStep = prevCount;
      dispatch(
        activeConsultantVerificationStep({
          ConsultantVerificationStep: stateStep,
        })
      );
      return prevCount;
    });
  };

  const handleVerificationPrevious = async (): Promise<void> => {
    setStatusConst('NONE');
    isSubmit();
  };

  const handleVerificationUpdate = async (): Promise<void> => {
    setStatusConst('PROJECT_STEP');
    isSubmit();
  };

  const isSubmit = async (statusTypeStatus?: consultantRegisterStepType): Promise<void> => {
    if (statusTypeStatus) {
      setStatusConst(statusTypeStatus);
    }
    if (isDirty) {
      trigger('workInfoList');
      getValues('workInfoList').forEach((workInfo, index) => {
        if (workInfo.atWorkDateRangeStart)
          setValue(
            `workInfoList.${index}.atWorkDateRangeStart`,
            new Date(workInfo.atWorkDateRangeStart)
          );
        if (workInfo.atWorkDateRangeEnd)
          setValue(
            `workInfoList.${index}.atWorkDateRangeEnd`,
            new Date(workInfo.atWorkDateRangeEnd)
          );
      });
      trigger('educationInfoList');
      getValues('educationInfoList').forEach((educationInfo, index) => {
        if (educationInfo.atSchoolDateRangeStart)
          setValue(
            `educationInfoList.${index}.atSchoolDateRangeStart`,
            new Date(educationInfo.atSchoolDateRangeStart)
          );
        if (educationInfo.atSchoolDateRangeEnd)
          setValue(
            `educationInfoList.${index}.atSchoolDateRangeEnd`,
            new Date(educationInfo.atSchoolDateRangeEnd)
          );
      });
      trigger('projectInfoList');
      setIsDialogOpenJump(true);
    } else {
      dispatch(
        consultantPrevStateAction({
          id: verificationId,
          consultantId,
          basicInfo: consultant.verification?.basicInfo,
          certificateInfoList: consultant.verification?.certificateInfoList,
          companyResourceInfoList: consultant.verification?.companyResourceInfoList,
          educationInfoList: consultant.verification?.educationInfoList,
          honorInfoList: consultant.verification?.honorInfoList,
          languageInfoList: consultant.verification?.languageInfoList,
          projectInfoList: consultant.verification?.projectInfoList,
          workInfoList: consultant.verification?.workInfoList,
          settings: consultant.verification?.settings,
          otherInfo: consultant.verification?.otherInfo,
          consultantType: 'PERSONAL',
          status: consultant.verification?.status,
        })
      );
      setStatusConst(prevCount => {
        const stateStep = prevCount;
        dispatch(
          activeConsultantVerificationStep({
            ConsultantVerificationStep: stateStep,
          })
        );
        return prevCount;
      });
    }
  };

  useEffect(() => {
    if (consultant && consultant.verification) {
      if (consultant.verification.workInfoList) {
        const workInfoData = JSON.stringify(consultant.verification.workInfoList);
        const data = JSON.parse(workInfoData);
        data.forEach((workInfo: ConsultantWorkInfo) => {
          if (typeof workInfo.professionalClass == 'string')
            workInfo.professionalClass = JSON.parse(workInfo.professionalClass);
          if (typeof workInfo.industryClass == 'string')
            workInfo.industryClass = JSON.parse(workInfo.industryClass);
        });
        setValue('workInfoList', data);
      }
      if (consultant.verification?.educationInfoList) {
        setValue('educationInfoList', consultant.verification.educationInfoList);
      }
      if (consultant.verification.projectInfoList) {
        setValue('projectInfoList', consultant.verification.projectInfoList);
      }
    }
  }, [
    consultant.verification?.workInfoList,
    consultant.verification?.educationInfoList,
    consultant.verification?.projectInfoList,
  ]);

  return (
    <>
      {consultantType === 'PERSONAL' && (
        <ConsultantStepBar handleStepUpdate={isSubmit}></ConsultantStepBar>
      )}
      <Grid id="basicView" item xs={12}>
        <Paper className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Typography align="left" variant="h6" className={classes.infoTitle}>
                {t('work_view:work_information')}
              </Typography>
            </Grid>
            <Divider />
            {workInfoList.length > 0
              ? workInfoList.map((field, index) => {
                  return (
                    <WorkInputView
                      key={field.id}
                      index={index}
                      control={(control as unknown) as ConsultantWorkInfoControl}
                      errors={errors}
                      setValue={setValue}
                      remove={handleWorkInfoDelete}
                    />
                  );
                })
              : appendWorkInfoList(createConsultantWorkInfo())}
            <Button
              className={classes.ButtonMargin}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleWorkInfoAdd}
            >
              <PlaylistAddIcon fontSize="small" />
              {t('work_view:add')}
            </Button>
          </Grid>

          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Typography align="left" variant="h6" className={classes.infoTitle}>
                {t('education_view:educational_information')}
              </Typography>
            </Grid>
            <Divider />
            {educationInfoList.length > 0
              ? educationInfoList.map((field, index) => {
                  return (
                    <EducationInputView
                      key={field.id}
                      index={index}
                      control={(control as unknown) as ConsultantEducationInfoControl}
                      errors={errors}
                      setValue={setValue}
                      remove={handleEducationInfoDelete}
                    />
                  );
                })
              : appendEducationInfoList(createConsultantEducationInfo())}
            <Button
              className={classes.ButtonMargin}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleEducationInfoAdd}
            >
              <PlaylistAddIcon fontSize="small" />
              {t('education_view:add')}
            </Button>
          </Grid>
          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Typography align="left" variant="h6" className={classes.infoTitle}>
                {t('project_experience_view:project_experience')}
              </Typography>
            </Grid>
            <Divider />
            {projectInfoList.length > 0
              ? projectInfoList.map((field, index) => {
                  return (
                    <ProjectExperienceInputView
                      verificationId={verificationId}
                      key={field.id}
                      index={index}
                      control={(control as unknown) as ConsultantProjectInfoControl}
                      errors={errors}
                      setValue={setValue}
                      removeList={handleProjectExperienceDelete}
                      deleteFile={EditInformationDialog}
                    />
                  );
                })
              : appendProjectInfoList(createConsultantProjectInfo())}
            <Button
              className={classes.ButtonMargin}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleProjectExperienceAdd}
            >
              <PlaylistAddIcon fontSize="small" />
              {t('project_experience_view:add')}
            </Button>
          </Grid>
          <Grid item container xs={12} className={classes.bothButton}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleVerificationPrevious}
            >
              <SkipPreviousIcon fontSize="small" />
              {t('work_view:back')}
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              // disabled={!(isDirty && !!dirtyFields.basicInfo)}
              onClick={(): void => {
                handleVerificationUpdate();
              }}
            >
              {t('work_view:next_step')}
              <SkipNextIcon fontSize="small" />
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <EditInformationJumpDialog
        isOpen={isDialogOpenJump}
        handleEdit={() => {
          EditInformationDialog();
        }}
        handleDialogClose={() => {
          CancelSubmission();
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  consultant?: Consultant;
}

export default withStyles(styles)(WorkExperienceView);
