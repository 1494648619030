import React, { ReactElement } from 'react';
import {
  Control, //类型
  Controller,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from 'i18next';

import debug from '../../../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    infoTitle: {
      marginTop: '10px',
    },
  });

const PersonalHonorInputView = ({
  classes,
  index,
  control,
  // errors,
  remove,
  summaryMode = false,
}: Props): ReactElement => {
  debug('Load language input view');
  const { t } = useTranslation();
  i18n.loadNamespaces('PersonalHonors');
  return (
    <>
      <Grid container item xs={12}>
        <Typography align="left" variant="subtitle1" className={classes.infoTitle}>
          {t('personal_honors:honors_achievements')}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Tooltip
                TransitionComponent={Zoom}
                title={!summaryMode ? t('personal_honors:personal_honor_tooltip') : ''}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  autoFocus={false}
                  label={t('personal_honors:personal_honor')}
                  disabled={summaryMode}
                  // error={!!errors?.personalHonorList?.[index]?.honorTitle}
                  // helperText={errors?.personalHonorList?.[index]?.honorTitle && '个人荣誉不能为空'}
                  {...field}
                />
              </Tooltip>
            )}
            name={`personalHonorList.${index}.honorTitle`}
            // rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  // error={!!errors?.personalHonorList?.[index]?.startTime}
                  fullWidth
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      format="yyyy-MM"
                      views={['year', 'month']}
                      id="date-picker-start"
                      label={t('personal_honors:start_time')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={summaryMode}
                      readOnly={summaryMode}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                  {/* {errors?.personalHonorList?.[index]?.startTime && (
                    <FormHelperText>开始时间不能为空</FormHelperText>
                  )} */}
                </FormControl>
              );
            }}
            name={`personalHonorList.${index}.honorDateRangeStart`}
            // rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl margin="normal" variant="outlined" fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    format="yyyy-MM"
                    views={['year', 'month']}
                    id="date-picker-end"
                    label={t('personal_honors:end_time')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                    value={field.value ?? null}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
            name={`personalHonorList.${index}.honorDateRangeEnd`}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          render={({ field }) => (
            <Tooltip
              TransitionComponent={Zoom}
              title={!summaryMode ? t('personal_honors:honors_introduction_tooltip') : ''}
            >
              <TextField
                multiline
                minRows={4}
                fullWidth
                variant="outlined"
                autoFocus={false}
                label={t('personal_honors:honors_introduction')}
                disabled={summaryMode}
                // error={!!errors?.personalHonorList?.[index]?.content}
                // helperText={errors?.personalHonorList?.[index]?.content && '荣誉简介不能为空'}
                {...field}
              />
            </Tooltip>
          )}
          name={`personalHonorList.${index}.honorContent`}
          control={control}
          // rules={{ required: true }}
        ></Controller>
      </Grid>
      {!summaryMode && remove && (
        <Grid item container xs={12} alignItems="center" className={classes.bottomButton}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                remove(index);
              }}
            >
              {t('personal_honors:delete')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
// 导出类型
export type ConsultantPersonalHonorControl = Control<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
  otherInfo: ConsultantOtherInfo;
}>;
export type ConsultantPersonalHonorSetValue = UseFormSetValue<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
}>;
export type ConsultantPersonalHonorErrors = FieldErrors<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
}>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  index: number;
  control: ConsultantPersonalHonorControl;
  setValue?: ConsultantPersonalHonorSetValue;
  errors?: ConsultantPersonalHonorErrors;
  remove?: UseFieldArrayRemove;
  summaryMode?: boolean;
}

export default withStyles(styles)(PersonalHonorInputView);
