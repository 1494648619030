import React, { ReactElement } from 'react';
import {
  Control, //类型
  Controller,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  // FormHelperText,
  Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import i18n from 'i18next';

import debug from '../../../utils/debug';
import LANGUAGE_WORLD from '../../../utils/languageType';

const styles = (): StyleRules =>
  createStyles({
    infoTitle: {
      marginTop: '10px',
    },
  });

const LanguagesInputView = ({
  classes,
  index,
  control,
  remove,
  // errors,
  summaryMode = false,
}: Props): ReactElement => {
  debug('Load language input view');
  const { t } = useTranslation();
  i18n.loadNamespaces('LanguagesInputView');
  LANGUAGE_WORLD.sort((a, b) => a.label.localeCompare(b.label));
  return (
    <>
      <Grid container item xs={12}>
        <Typography align="left" variant="subtitle1" className={classes.infoTitle}>
          {t('languages_input_view:language')}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  // error={!!errors?.languageInfo?.languageSkillList?.[index]?.languageName}
                >
                  <InputLabel id="label-education">{t('languages_input_view:language')}</InputLabel>
                  <Select
                    labelId="label-education"
                    id="education"
                    label="语言"
                    fullWidth
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                  >
                    <MenuItem key={'s1'} value={''} disabled>
                      -{t('languages_input_view:please_select')}-
                    </MenuItem>
                    {LANGUAGE_WORLD.map((lg, indexlg) => {
                      return (
                        <MenuItem key={indexlg} value={lg.value}>
                          {i18n.language == 'enUS' ? lg.value : lg.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* {errors?.languageInfo?.languageSkillList?.[index]?.languageName && (
                    <FormHelperText>语言不能为空</FormHelperText>
                  )} */}
                </FormControl>
              );
            }}
            name={`languageInfoList.${index}.languageName`}
            control={control}
            // rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                // error={!!errors?.languageInfo?.languageSkillList?.[index]?.languageLevel}
              >
                <InputLabel id="label-major">
                  {t('languages_input_view:proficiency_level')}
                </InputLabel>
                <Select
                  labelId="label-major"
                  id="major"
                  label={t('languages_input_view:proficiency_level')}
                  fullWidth
                  disabled={summaryMode}
                  readOnly={summaryMode}
                  {...field}
                >
                  <MenuItem key={'s2'} value={''} disabled>
                    -{t('languages_input_view:please_select')}-
                  </MenuItem>
                  <MenuItem key={1} value={'Beginner'}>
                    {t('languages_input_view:beginner')}
                  </MenuItem>
                  <MenuItem key={2} value={'Intermediate'}>
                    {t('languages_input_view:intermediate')}
                  </MenuItem>
                  <MenuItem key={3} value={'Advanced'}>
                    {t('languages_input_view:advanced')}
                  </MenuItem>
                  <MenuItem key={4} value={'Proficiency'}>
                    {t('languages_input_view:proficiency')}
                  </MenuItem>
                </Select>
                {/* {errors?.languageInfo?.languageSkillList?.[index]?.languageLevel && (
                  <FormHelperText>熟练度不能为空</FormHelperText>
                )} */}
              </FormControl>
            )}
            name={`languageInfoList.${index}.languageLevel`}
            control={control}
            // rules={{ required: true }}
          ></Controller>
        </Grid>
      </Grid>

      {!summaryMode && remove && (
        <Grid item container xs={12} alignItems="center" className={classes.bottomButton}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                remove(index);
              }}
            >
              {t('languages_input_view:delete')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
// 导出类型
export type ConsultantLanguageInfoControl = Control<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
  otherInfo: ConsultantOtherInfo;
}>;
export type ConsultantLanguageInfoSetValue = UseFormSetValue<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
}>;
export type ConsultantLanguageInfoErrors = FieldErrors<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
}>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  index: number;
  control: ConsultantLanguageInfoControl;
  setValue?: ConsultantLanguageInfoSetValue;
  errors?: ConsultantLanguageInfoErrors;
  remove?: UseFieldArrayRemove;
  summaryMode?: boolean;
}

export default withStyles(styles)(LanguagesInputView);
