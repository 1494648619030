import React, { ReactElement, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  createStyles,
  Grid,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Image } from 'antd';
import imageCompression from 'browser-image-compression';
import i18n from 'i18next';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

import { DeleteConfirmDialog } from '../../../components/Dialog';
import { CertificateTypeArray, CertificateTypeArrayEn } from '../../../utils/CertificateType';
import debug from '../../../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    infoTitle: {
      marginTop: '10px',
    },
  });

const CertificateInputView = ({
  classes,
  index,
  control,
  // errors,
  deleteFile,
  remove,
  summaryMode = false,
}: Props): ReactElement => {
  debug('Load language input view');
  const { t } = useTranslation();
  i18n.loadNamespaces('CertificateInputView');

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const { fields: CertificatesFileList, append: addImage, remove: removeImage } = useFieldArray({
    control,
    name: `consultantCertificateList.${index}.certificateFileList`,
  });

  const compressFile = async (fileToCompress: File): Promise<string> => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile: File;
    try {
      compressedFile = await imageCompression(fileToCompress, options);
      debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    } catch (error) {
      debug('Compress file', error);
    }
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result as string);
      reader.readAsDataURL(compressedFile);
    });
  };

  const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    debug('Handle on save', fileObjects);
    for (let index = 0; index < fileObjects.length; index++) {
      const element = fileObjects[index].file;
      const fileToUpload = await compressFile(element);
      addImage({ id: undefined, file: fileToUpload });
    }
  };

  const handleDelete = async (): Promise<void> => {
    // await dispatch(deleteBasicFile(deleteId, verificationId));
    removeImage(deleteId);
    deleteFile ? deleteFile(true) : '';
    setIsDialogOpen(false);
  };

  return (
    <>
      <Grid container item xs={12}>
        <Typography align="left" variant="subtitle1" className={classes.infoTitle}>
          {t('certificate_input_view:my_certificate')}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Tooltip
                TransitionComponent={Zoom}
                title={!summaryMode ? t('certificate_input_view:certificate_name_tooltip') : ''}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  autoFocus={false}
                  label={t('certificate_input_view:certificate_name')}
                  disabled={summaryMode}
                  // error={!!errors?.personalHonorList?.[index]?.title}
                  // helperText={errors?.personalHonorList?.[index]?.title && '个人荣誉不能为空'}
                  {...field}
                />
              </Tooltip>
            )}
            name={`consultantCertificateList.${index}.certificateName`}
            // rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                defaultValue={null}
                id="combo-box-demo"
                options={i18n.language == 'enUS' ? CertificateTypeArrayEn : CertificateTypeArray}
                getOptionLabel={option => option.label}
                getOptionDisabled={option => option.disabled == true}
                fullWidth
                disabled={summaryMode}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label={t('certificate_input_view:certificate_type')}
                      variant="outlined"
                    />
                  );
                }}
                value={
                  (i18n.language == 'enUS' ? CertificateTypeArrayEn : CertificateTypeArray).find(
                    option => option.value === field.value
                  ) || null
                } // 设置 Autocomplete 的 value 属性
                onChange={(_, newValue) => {
                  field.onChange(newValue?.value || null); // 更新 React Hook Form 字段值
                }}
              />
            )}
            name={`consultantCertificateList.${index}.certificateDescription`}
            control={control}
            // rules={{ required: true }}
          ></Controller>
        </Grid>
      </Grid>
      {!summaryMode && (
        <Grid item container xs={12} className={classes.submit}>
          <Grid item xs={7}>
            <Typography
              variant="body2"
              align="left"
              color="secondary"
              className={classes.attachment}
            >
              {t('certificate_input_view:upload_certificate_file_description')}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Tooltip
              TransitionComponent={Zoom}
              title={!summaryMode ? t('certificate_input_view:upload_certificate_tooltip') : ''}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.bheight}
                onClick={() => {
                  setIsUploadDialogOpen(true);
                }}
              >
                {t('certificate_input_view:upload_certificate')}
              </Button>
            </Tooltip>
          </Grid>
          <DropzoneDialogBase
            fileObjects={[]}
            open={isUploadDialogOpen}
            onAdd={(fileObjects): void => {
              handleOnSave(fileObjects);
            }}
            showPreviews={true}
            maxFileSize={50000000}
            dropzoneText={t('certificate_input_view:drag_files')}
            dialogTitle={t('certificate_input_view:uploading')}
            submitButtonText={t('certificate_input_view:uploading')}
            cancelButtonText={t('certificate_input_view:cancellation')}
            onClose={(): void => {
              setIsUploadDialogOpen(false);
            }}
          />
        </Grid>
      )}
      {CertificatesFileList.map((file, indexImg) => {
        return (
          <Grid key={file.id} item xs={12}>
            <Controller
              render={({ field: { value } }) => (
                <>
                  <Image src={value.file} width={300} />
                  <br></br>
                  {!summaryMode && (
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (value.id) {
                          setIsDialogOpen(true);
                          setDeleteId(indexImg);
                        } else {
                          removeImage(indexImg);
                        }
                      }}
                    >
                      {t('certificate_input_view:delete_picture')}
                    </Button>
                  )}
                </>
              )}
              name={`consultantCertificateList.${index}.certificateFileList.${indexImg}`}
              control={control}
            ></Controller>
          </Grid>
        );
      })}
      {!summaryMode && remove && (
        <Grid item container xs={12} alignItems="center" className={classes.bottomButton}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                remove(index);
              }}
            >
              {t('certificate_input_view:delete')}
            </Button>
          </Grid>
        </Grid>
      )}
      <DeleteConfirmDialog
        isOpen={isDialogOpen}
        handleDelete={handleDelete}
        handleDialogClose={() => {
          setIsDialogOpen(false);
        }}
      ></DeleteConfirmDialog>
    </>
  );
};
// 导出类型
export type ConsultantCertificateControl = Control<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
  otherInfo: ConsultantOtherInfo;
}>;
export type ConsultantCertificateSetValue = UseFormSetValue<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
}>;
export type ConsultantCertificateErrors = FieldErrors<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
}>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  index: number;
  control: ConsultantCertificateControl;
  setValue?: ConsultantCertificateSetValue;
  deleteFile?: Function;
  errors?: ConsultantCertificateErrors;
  remove?: UseFieldArrayRemove;
  summaryMode?: boolean;
}

export default withStyles(styles)(CertificateInputView);
