import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyleRules, WithStyles } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import i18n from 'i18next';

import { AppState } from '../../../reducers';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    button: {
      marginRight: theme.spacing(1),
    },

    completed: {
      display: 'inline-block',
    },
  });

const stepBarValue = [
  'NONE',
  'PERSONAL_STEP',
  'PROJECT_STEP',
  'CAPACITY_STEP',
  'SETTINGS_STEP',
  'SUMMARY_STEP',
];

const VerificationStepper = ({ classes, handleStepUpdate }: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('step_bar');
  //   const classes = styles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set<number>());
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = [
    t('step_bar:basic_information_required'),
    // t('step_bar:education_information_optional'),
    t('step_bar:work_information_optional'),
    // t('step_bar:project_information_optional'),
    t('step_bar:other_abilities_optional'),
    t('step_bar:other_settings_optional'),
  ];
  const totalSteps = () => {
    return steps.length;
  };

  const consultantVerificationStep = useSelector(
    (appState: AppState) => appState.consultantVerificationStep
  );

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  handleNext;
  setCompleted;
  setSkipped;

  const handleStep = (step: number) => () => {
    handleStepUpdate(stepBarValue[step]);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  function isStepComplete(step: number) {
    return completed.has(step);
  }

  useEffect(() => {
    switch (consultantVerificationStep.ConsultantVerificationStep) {
      case 'NONE': {
        setActiveStep(0);
        break;
      }
      case 'PERSONAL_STEP': {
        setActiveStep(1);
        break;
      }
      case 'PROJECT_STEP': {
        setActiveStep(2);
        break;
      }
      case 'CAPACITY_STEP': {
        setActiveStep(3);
        break;
      }
    }
  }, [consultantVerificationStep]);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const buttonProps: { optional?: React.ReactNode } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};
export interface Props extends WithStyles<typeof styles> {
  className?: string;
  handleStepUpdate: Function;
}
export default withStyles(styles)(VerificationStepper);
