import React, { ReactElement, useEffect, useState } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { FieldErrors, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Avatar, Image } from 'antd';
import imageCompression from 'browser-image-compression';
import i18n from 'i18next';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

// import { Alert } from '@material-ui/lab';
import { DeleteConfirmDialog } from '../../../components/Dialog';
import logo from '../../../images/fintegrity-logo.jpeg';
import { showNotificationAction } from '../../../reducers/notificationReducer';
import CITY from '../../../utils/city';
import countyLess from '../../../utils/cityChild';
import COUNTRY from '../../../utils/country';
import debug from '../../../utils/debug';
import PROVINCE from '../../../utils/province';
import { City } from '../consultant.model';

const styles = (): StyleRules =>
  createStyles({
    helperText: {
      color: 'red',
    },
    submit: {
      textAlign: 'center',
    },
    AvatarBorder: {
      textAlign: 'center',
      '&:hover': {
        border: '10px',
      },
    },
    buttonHeight: {
      margin: 'auto 0',
      height: '36.5px',
    },
    avatarColor: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  });
const BasicInfoView = ({
  classes,
  errors,
  setValue,
  getValues,
  control,
  basicInfo,
  deleteFile,
  summaryMode = false,
  editBasicInfoMode = false,
}: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('personal_info_input_view');

  const provinceList: Array<{ code: string; name: string; en: string }> = PROVINCE;
  const cityList: Array<City> = CITY;
  const county: Array<{
    code: string;
    name: string;
    cityCode: string;
    enName: string;
  }> = countyLess;
  const dispatch = useDispatch();
  const [verifyDisabled, setVerifyDisabled] = useState<boolean>(false);
  verifyDisabled;
  const [selectProvinceChar, setSelectProvinceChar] = useState<string | number>();
  const [selectCityChar, setSelectCityChar] = useState<string | number>();

  const [deleteId, setDeleteId] = useState<number>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [avatarName, setAvatarName] = useState('');
  const [avatarImg, setAvatarImg] = useState('');

  const { fields: personalDescriptionFileList, append, remove } = useFieldArray({
    control,
    name: 'basicInfo.personalDescriptionFileList',
  });

  const compressFile = async (fileToCompress: File): Promise<string> => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile: File;
    try {
      compressedFile = await imageCompression(fileToCompress, options);
      debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    } catch (error) {
      debug('Compress file', error);
    }
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result as string);
      reader.readAsDataURL(compressedFile);
    });
  };
  const handleDelete = () => {
    remove(deleteId);
    deleteFile ? deleteFile(true) : '';
    setIsDialogOpen(false);
  };

  const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    debug('Handle on save', fileObjects);
    if (avatarName == 'Avatar') {
      if (fileObjects.length == 1) {
        const AvatarData = await compressFile(fileObjects[0].file);
        setAvatarImg(AvatarData);
        setValue('basicInfo.avatar', AvatarData, {
          shouldDirty: true,
        });
        setAvatarName('');
        return;
      } else {
        dispatch(
          showNotificationAction({
            severity: 'error',
            message: t('personal_info_input_view:cannot_multiple_avatars'),
          })
        );
        return;
      }
    }
    for (let index = 0; index < fileObjects.length; index++) {
      const element = fileObjects[index].file;
      const fileToUpload = await compressFile(element);
      append({ id: undefined, file: fileToUpload });
    }
  };

  const AvatarHandle = (): void => {
    setAvatarName('Avatar');
    setIsUploadDialogOpen(true);
  };

  const validate = (value: string | undefined) => {
    if (value == undefined) {
      return;
    }
    const reg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])(\d|X|x){4}$/;
    const reg_gat = /^[HMhm]?([0-9]{10}|[0-9]{8})$/;
    if (getValues('basicInfo.passportType') == 'IDCard' && value) {
      if (!reg.test(value)) {
        return '输入身份证格式不正确！';
      }
    }
    if (getValues('basicInfo.passportType') == 'HongKong_Macao_Taiwan' && value) {
      if (!reg_gat.test(value)) {
        return '输入港澳台通行证格式不正确！';
      }
    }
  };

  useEffect(() => {
    basicInfo?.nowAddressProvince && setSelectProvinceChar(basicInfo?.nowAddressProvince);
    basicInfo.nowAddressCity && setSelectCityChar(basicInfo.nowAddressCity);

    if (basicInfo?.personalDescriptionFileList) {
      setValue('basicInfo.personalDescriptionFileList', basicInfo.personalDescriptionFileList);
    }
    if (basicInfo?.avatar) {
      setAvatarImg(basicInfo.avatar);
    }
  }, [basicInfo]);

  useEffect(() => {
    editBasicInfoMode && setVerifyDisabled(false);
  }, [editBasicInfoMode]);

  useEffect(() => {
    if (i18n.language == 'zhCN') {
      COUNTRY.sort((a, b) => a.name.localeCompare(b.name));
      provinceList.sort((a, b) => a.name.localeCompare(b.name));
    } else if (i18n.language == 'enUS') {
      COUNTRY.sort((a, b) => a.value.localeCompare(b.value));
      provinceList.sort((a, b) => a.en.localeCompare(b.en));
    }
  }, [i18n.language]);

  return (
    <>
      <Grid item container xs={5}>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item container xs={3}>
            <div className={classes.avatarColor}>
              <Controller
                render={({ field: { value } }) => (
                  <Avatar
                    onClick={AvatarHandle}
                    className={classes.AvatarBorder}
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }}
                    src={value ? value : logo}
                  />
                )}
                name="basicInfo.avatar"
                control={control}
              ></Controller>
              <div>
                {avatarImg != '' ? (
                  <Typography variant="caption" display="block" gutterBottom>
                    {t('personal_info_input_view:edit_avatar')}
                  </Typography>
                ) : (
                  <Typography variant="caption" display="block" gutterBottom>
                    {t('personal_info_input_view:upload_avatar')}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid item container xs={3}>
            <Controller
              render={({ field }) => (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={!summaryMode ? t('personal_info_input_view:first_ame_tooltip') : ''}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={t('personal_info_input_view:first_name')}
                    error={!!errors?.basicInfo?.firstName}
                    helperText={
                      errors?.basicInfo?.firstName &&
                      t('personal_info_input_view:first_name_not_empty')
                    }
                    disabled={summaryMode}
                    {...field}
                  />
                </Tooltip>
              )}
              name="basicInfo.firstName"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid item container xs={5}>
            <Controller
              render={({ field }) => (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={!summaryMode ? t('personal_info_input_view:last_name_tooltip') : ''}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="LastName"
                    label={t('personal_info_input_view:last_name')}
                    error={!!errors?.basicInfo?.lastName}
                    helperText={
                      errors?.basicInfo?.lastName && t('personal_info_input_view:name_not_empty')
                    }
                    disabled={summaryMode}
                    {...field}
                  />
                </Tooltip>
              )}
              name="basicInfo.lastName"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
        </Grid>
        <Controller
          render={({ field }) => (
            <FormControl
              margin="normal"
              variant="outlined"
              error={!!errors?.basicInfo?.gender}
              fullWidth
            >
              <InputLabel id="label-gender">{t('personal_info_input_view:gender')}</InputLabel>
              <Select
                labelId="label-gender"
                id="gender"
                label={t('personal_info_input_view:gender')}
                fullWidth
                readOnly={summaryMode}
                disabled={summaryMode}
                {...field}
              >
                <MenuItem value={''} disabled>
                  -{t('personal_info_input_view:please_select')}-
                </MenuItem>
                <MenuItem value={'male'}>{t('personal_info_input_view:male')}</MenuItem>
                <MenuItem value={'female'}>{t('personal_info_input_view:female')}</MenuItem>
              </Select>
              {errors?.basicInfo?.gender && (
                <FormHelperText>{t('personal_info_input_view:gender_not_empty')}</FormHelperText>
              )}
            </FormControl>
          )}
          name="basicInfo.gender"
          rules={{ required: true }}
          control={control}
        ></Controller>
        <Grid item xs={12}>
          <Controller
            render={({ field: { name, value, onChange, ref } }) => {
              return (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  error={!!errors?.basicInfo?.birthday}
                  fullWidth
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      error={!!errors?.basicInfo?.birthday}
                      fullWidth
                      label={t('personal_info_input_view:birthday')}
                      format="yyyy-MM-dd"
                      views={['year', 'month', 'date']}
                      variant="inline"
                      id="date-picker-year-month"
                      name={name}
                      value={value ? value : null}
                      inputRef={ref}
                      onChange={onChange}
                      autoOk={true}
                      disabled={summaryMode}
                      readOnly={summaryMode}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.basicInfo?.birthday && (
                    <FormHelperText>
                      {t('personal_info_input_view:birthday_not_empty')}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
            rules={{
              required: true,
            }}
            name="basicInfo.birthday"
            control={control}
          />
        </Grid>
        <Controller
          render={({ field }) => (
            <FormControl
              margin="normal"
              variant="outlined"
              fullWidth
              error={!!errors?.basicInfo?.nationality}
            >
              <InputLabel id="label-nationality">
                {t('personal_info_input_view:nationality')}
              </InputLabel>
              <Select
                labelId="label-nationality"
                id="nationality"
                label={t('personal_info_input_view:nationality')}
                fullWidth
                readOnly={summaryMode}
                disabled={summaryMode}
                {...field}
              >
                <MenuItem key={''} value={''} disabled>
                  -{t('personal_info_input_view:please_select')}-
                </MenuItem>
                {COUNTRY.map(country => {
                  return (
                    <MenuItem key={country.value} value={country.value}>
                      {i18n.language == 'enUS' ? country.value : country.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors?.basicInfo?.nationality && (
                <FormHelperText>
                  {t('personal_info_input_view:nationality_not_blank')}
                </FormHelperText>
              )}
            </FormControl>
          )}
          name="basicInfo.nationality"
          control={control}
        ></Controller>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item container xs={5}>
            <Controller
              render={({ field }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.basicInfo?.passportType}
                >
                  <InputLabel id="label-passportType">
                    {t('personal_info_input_view:id_type')}
                  </InputLabel>
                  <Select
                    labelId="label-passportType"
                    id="passportType"
                    label={t('personal_info_input_view:id_type')}
                    fullWidth
                    readOnly={summaryMode}
                    disabled={summaryMode}
                    {...field}
                  >
                    <MenuItem value={''} disabled>
                      -{t('personal_info_input_view:please_select')}-
                    </MenuItem>
                    <MenuItem value={'IDCard'}>
                      {t('personal_info_input_view:identity_card')}
                    </MenuItem>
                    <MenuItem value={'Passport'}>{t('personal_info_input_view:passport')}</MenuItem>
                    <MenuItem value={'HongKong_Macao_Taiwan'}>
                      {t('personal_info_input_view:hongkong_macao_taiwan_pass')}
                    </MenuItem>
                  </Select>
                  {errors?.basicInfo?.passportType && (
                    <FormHelperText>
                      {t('personal_info_input_view:select_identity_card')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              name="basicInfo.passportType"
              rules={{
                required: true,
              }}
              control={control}
            ></Controller>
          </Grid>
          <Grid item container xs={5}>
            {!summaryMode && (
              <Tooltip
                TransitionComponent={Zoom}
                title={!summaryMode ? t('personal_info_input_view:upload_id_Tooltip') : ''}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.buttonHeight}
                  onClick={() => {
                    setAvatarName('');
                    setIsUploadDialogOpen(true);
                  }}
                >
                  {t('personal_info_input_view:upload_id')}
                </Button>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item container xs={5}>
        <Controller
          render={({ field }) => (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="contactEmail"
              label={t('personal_info_input_view:mailbox')}
              error={!!errors?.basicInfo?.contactEmail}
              helperText={
                errors?.basicInfo?.contactEmail &&
                (errors?.basicInfo?.contactEmail?.type === 'required'
                  ? t('personal_info_input_view:email_not_empty')
                  : t('personal_info_input_view:incorrect_email_format'))
              }
              disabled={summaryMode}
              {...field}
            />
          )}
          name="basicInfo.contactEmail"
          control={control}
          rules={{
            required: true,
            pattern: /^[A-Za-z0-9_-]+([._-][A-Za-z0-9_-]+)*@([A-Za-z0-9-]+[.])+[A-Za-z]{2,}$/,
          }}
        ></Controller>
        <Controller
          render={({ field }) => (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label={t('personal_info_input_view:phone')}
              error={!!errors?.basicInfo?.phoneNumber}
              helperText={
                !!errors?.basicInfo?.phoneNumber &&
                t('personal_info_input_view:mobile_number_not_used')
              }
              disabled={summaryMode}
              {...field}
            />
          )}
          name="basicInfo.phoneNumber"
          control={control}
          rules={{
            required: true,
          }}
        ></Controller>
        <Typography variant="body2" align="left" color="secondary">
          {t('personal_info_input_view:current_residential_area')}
        </Typography>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item xs={4}>
            <Controller
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.basicInfo?.nowAddressProvince}
                >
                  <InputLabel id="label-nowAddress-province">
                    {t('personal_info_input_view:province')}
                  </InputLabel>
                  <Select
                    labelId="label-nowAddress-province"
                    id="province"
                    label="行政省"
                    value={value}
                    name={name}
                    inputRef={ref}
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    onChange={e => {
                      onChange(e);
                      setSelectProvinceChar(e.target.value as string);
                      setValue('basicInfo.nowAddressDistrict', '');
                      setValue('basicInfo.nowAddressCity', '');
                    }}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -{t('personal_info_input_view:please_select')}-
                    </MenuItem>
                    {[...provinceList].map(province => {
                      return (
                        <MenuItem key={province.code} value={province.code}>
                          {i18n.language == 'enUS' ? province.en : province.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors?.basicInfo?.passportType && <FormHelperText>选择省</FormHelperText>}
                </FormControl>
              )}
              name="basicInfo.nowAddressProvince"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field: { name, value, onChange } }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  error={!!errors?.basicInfo?.nowAddressCity}
                  fullWidth
                >
                  <InputLabel id="label-nowAddress-city">
                    {t('personal_info_input_view:city')}
                  </InputLabel>
                  <Select
                    labelId="label-nowAddress-city"
                    id="city"
                    label="行政市"
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    value={value}
                    name={name}
                    onChange={e => {
                      onChange(e);
                      setSelectCityChar(e.target.value as string);
                      setValue('basicInfo.nowAddressDistrict', '');
                    }}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -{t('personal_info_input_view:please_select')}-
                    </MenuItem>
                    {[...cityList]
                      .filter(city => city.provinceCode === selectProvinceChar)
                      .map(city => {
                        return (
                          <MenuItem key={city.code} value={city.code}>
                            {i18n.language == 'enUS' ? city.enName : city.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors?.basicInfo?.nowAddressCity && <FormHelperText>选择市</FormHelperText>}
                </FormControl>
              )}
              name="basicInfo.nowAddressCity"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
          <Grid item xs={4}>
            <Controller
              render={({ field }) => (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  error={!!errors?.basicInfo?.nowAddressDistrict}
                  fullWidth
                >
                  <InputLabel id="label-nowAddress-nowAddressDistrict">
                    {t('personal_info_input_view:now_address_district')}
                  </InputLabel>
                  <Select
                    labelId="label-nowAddress-nowAddressDistrict"
                    id="nowAddressDistrict"
                    label="行政区县"
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                  >
                    <MenuItem key={''} value={''} disabled>
                      -{t('personal_info_input_view:please_select')}-
                    </MenuItem>
                    {[...county]
                      .filter(city => city.cityCode === selectCityChar)
                      .map(city => {
                        return (
                          <MenuItem key={city.code} value={city.code}>
                            {i18n.language == 'enUS' ? city.enName : city.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors?.basicInfo?.nowAddressDistrict && (
                    <FormHelperText>选择区县</FormHelperText>
                  )}
                </FormControl>
              )}
              name="basicInfo.nowAddressDistrict"
              control={control}
              rules={{
                required: true,
              }}
            ></Controller>
          </Grid>
        </Grid>
        <Controller
          render={({ field }) => (
            <Tooltip
              TransitionComponent={Zoom}
              title={!summaryMode ? t('personal_info_input_view:address_tooltip') : ''}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="nowAddressDetail"
                label={t('personal_info_input_view:address')}
                disabled={summaryMode}
                {...field}
              />
            </Tooltip>
          )}
          name="basicInfo.nowAddressDetail"
          control={control}
        ></Controller>

        <Controller
          render={({ field }) => (
            <Tooltip
              TransitionComponent={Zoom}
              title={!summaryMode ? t('personal_info_input_view:id_number_tooltip') : ''}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="passportId"
                label={t('personal_info_input_view:id_number')}
                disabled={summaryMode}
                error={!!errors?.basicInfo?.passportId}
                helperText={
                  errors?.basicInfo?.passportId &&
                  (errors?.basicInfo?.passportId?.type === 'required'
                    ? t('personal_info_input_view:id_number_not_empty')
                    : errors?.basicInfo?.passportId?.message)
                }
                {...field}
              />
            </Tooltip>
          )}
          name="basicInfo.passportId"
          control={control}
          rules={{
            required: true,
            validate: validate,
          }}
        ></Controller>
      </Grid>
      {/* <Grid item xs={12} className={classes.submit}>
        <Controller
          render={({ field }) => (
            <Tooltip
              TransitionComponent={Zoom}
              title={t('personal_info_input_view:personal_description_tooltip')}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                disabled={summaryMode }
                id="personalDescription"
                label={t('personal_info_input_view:personal_description')}
                {...field}
              />
            </Tooltip>
          )}
          name="basicInfo.personalDescription"
          control={control}
        ></Controller>
      </Grid> */}
      {!summaryMode && (
        <Grid item container xs={12} className={classes.submit}>
          <Grid item xs={7}>
            <Typography
              variant="body2"
              align="left"
              color="secondary"
              className={classes.attachment}
            >
              {t('personal_info_input_view:upload_document_description')}
            </Typography>
          </Grid>
          {/* {basicInfo?.personalDescriptionFileList?.length == 0 && (
            <FormHelperText style={{ color: '#f44336' }}>
              {t('personal_info_input_view:file_id_not_empty')}
            </FormHelperText>
          )} */}
          <DropzoneDialogBase
            fileObjects={[]}
            open={isUploadDialogOpen}
            onAdd={(fileObjects): void => {
              handleOnSave(fileObjects);
            }}
            acceptedFiles={['.jpg,.jpeg,.bmp,.png']}
            showPreviews={true}
            maxFileSize={2097152}
            dropzoneText={t('personal_info_input_view:drag_files')}
            dialogTitle={t('personal_info_input_view:uploading')}
            submitButtonText={t('personal_info_input_view:uploading')}
            cancelButtonText={t('personal_info_input_view:cancellation')}
            onClose={(): void => {
              setIsUploadDialogOpen(false);
            }}
          />
        </Grid>
      )}
      {personalDescriptionFileList.map((file, index) => {
        return (
          <Grid key={file.id} item xs={12}>
            <Controller
              render={({ field: { value } }) => (
                <>
                  <Image src={value.file} width={300} />
                  <br></br>
                  {!summaryMode && (
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (value.id) {
                          setDeleteId(index);
                          setIsDialogOpen(true);
                        } else {
                          remove(index);
                        }
                      }}
                    >
                      {t('personal_info_input_view:delete')}
                    </Button>
                  )}
                </>
              )}
              name={`basicInfo.personalDescriptionFileList.${index}`}
              control={control}
            ></Controller>
          </Grid>
        );
      })}
      <DeleteConfirmDialog
        isOpen={isDialogOpen}
        handleDelete={handleDelete}
        handleDialogClose={() => {
          setIsDialogOpen(false);
        }}
      ></DeleteConfirmDialog>
    </>
  );
};
export type ConsultantBasicInfoControl = Control<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoSetValue = UseFormSetValue<{ basicInfo: ConsultantBasicInfo }>;
export type ConsultantBasicInfoGetValue = UseFormGetValues<{ basicInfo: ConsultantBasicInfo }>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  errors?: FieldErrors<{ basicInfo: ConsultantBasicInfo }>;
  setValue: ConsultantBasicInfoSetValue;
  control: ConsultantBasicInfoControl;
  verificationId?: number;
  basicInfo: ConsultantBasicInfo;
  summaryMode?: boolean;
  editBasicInfoMode?: boolean;
  getValues: ConsultantBasicInfoGetValue;
  deleteFile?: Function;
}

export default withStyles(styles)(BasicInfoView);
