import React, { ReactElement, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import classNames from 'classnames';

import { hideNotificationAction } from '../reducers/notificationReducer';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      top: '80px',
      [theme.breakpoints.up('md')]: {
        // paddingLeft: `${drawerWidth}px`,
        // width: `calc(100% - ${drawerWidth}px)`,
        width: '50%',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
      },
    },
    rootGuest: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 0,
      },
    },
    notificationAlert: {
      width: '50%',
      margin: '0 15px',
    },
  });

const Notification = ({
  classes,
  isVisible,
  severity,
  message,
  isGuest,
  autoHideDuration,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const handleClose = (event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideNotificationAction());
  };
  const rootClasses = classNames({
    [classes.root]: true,
    [classes.rootGuest]: isGuest,
  });

  return (
    <Snackbar
      className={rootClasses}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isVisible && message != undefined && message.trim().length > 0}
      onClose={handleClose}
      autoHideDuration={autoHideDuration || 5000}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
        className={classes.notificationAlert}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  isGuest?: boolean;
  isVisible?: boolean;
  severity?: 'info' | 'success' | 'warning' | 'error';
  message?: string;
  autoHideDuration?: number;
}
export default withStyles(styles)(Notification);
