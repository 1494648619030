import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Grid,
  Paper,
  StyleRules,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../../actions/consultant/consultantVerificationActions';
import { EditInformationJumpDialog } from '../../../components/Dialog';
import { AppState } from '../../../reducers';
import { consultantPrevStateAction } from '../../../reducers/consultantReducer';
import { showNotificationAction } from '../../../reducers/notificationReducer';
import { activeConsultantVerificationStep } from '../../../reducers/otherStatus';
import {
  createConsultantCertificate,
  createConsultantLanguageInfo,
  createConsultantMyIntroduction,
  createConsultantPersonalHonors,
} from '../consultant.model';
import CertificateInputView from './CertificateInputView';
import LanguageInputView from './LanguagesInputView';
import MyIntroductionInputView from './MyIntroductionInputView';
import PersonalHonors from './PersonalHonorsView';
import ConsultantStepBar from './VerificationStepperView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    formContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    submit: {
      textAlign: 'center',
    },
    infoTitle: {
      marginBottom: theme.spacing(1),
    },
    form: {
      width: '100%',
    },
    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      justifyContent: 'end',
    },
    btnMargin: {
      margin: '0 5px',
    },
    marginTopBtn: {
      margin: '20px 0',
    },
    ButtonMargin: {
      margin: '0 0.25rem',
    },
    bothButton: {
      padding: '2rem 0',
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

const LanguageSelectView = ({ classes }: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('LanguageSelectView');
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const [consultantType, setConsultantType] = useState<string>(
    consultant.verification?.consultantType || 'PERSONAL'
  );
  const verificationStatus = consultant.verification?.status;
  setConsultantType;
  const consultantId = consultant.id;
  const verificationId = consultant?.verification?.id;

  const { setValue, control, getValues, trigger, reset } = useForm({
    defaultValues: {
      languageInfoList: [createConsultantLanguageInfo()],
      personalHonorList: [createConsultantPersonalHonors()],
      consultantCertificateList: [createConsultantCertificate()],
      otherInfo: createConsultantMyIntroduction(),
    },
    mode: 'onChange',
  });
  const { isValid, isDirty = false, errors } = useFormState({ control });

  const setIsDirty = useCallback(
    isDirty => {
      reset({ isDirty });
    },
    [reset]
  );

  const { fields: languageInfoList, append, remove } = useFieldArray({
    control,
    name: 'languageInfoList',
  });
  const {
    fields: personalHonorList,
    append: addPersonalHonor,
    remove: removePersonalHonor,
  } = useFieldArray({
    control,
    name: 'personalHonorList',
  });
  const {
    fields: consultantCertificateList,
    append: addCertificates,
    remove: removeCertificates,
  } = useFieldArray({
    control,
    name: 'consultantCertificateList',
  });

  const [isDialogOpenJump, setIsDialogOpenJump] = useState<boolean>(false);
  const [statusConst, setStatusConst] = useState<consultantRegisterStepType>();

  const EditInformationDialog = async (isDeleteFile?: boolean): Promise<void> => {
    if (isValid) {
      getValues('personalHonorList').forEach((personalHonorItem, index) => {
        if (personalHonorItem.honorDateRangeStart) {
          setValue(
            `personalHonorList.${index}.honorDateRangeStart`,
            new Date(personalHonorItem.honorDateRangeStart)
          );
        }
        if (personalHonorItem.honorDateRangeEnd) {
          setValue(
            `personalHonorList.${index}.honorDateRangeEnd`,
            new Date(personalHonorItem.honorDateRangeEnd)
          );
        }
      });
      if (verificationStatus == 'SUBMITTED' || verificationStatus == 'VERIFIED') {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            languageInfoList: getValues('languageInfoList'),
            honorInfoList: getValues('personalHonorList'),
            certificateInfoList: getValues('consultantCertificateList'),
            otherInfo: getValues('otherInfo'),
          })
        );
      } else {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            languageInfoList: getValues('languageInfoList'),
            honorInfoList: getValues('personalHonorList'),
            certificateInfoList: getValues('consultantCertificateList'),
            otherInfo: getValues('otherInfo'),
            status: 'LANGUAGE_REGISTERED',
          })
        );
      }
      if (!isDeleteFile) {
        setStatusConst(prevCount => {
          const stateStep = prevCount;
          dispatch(
            activeConsultantVerificationStep({
              ConsultantVerificationStep: stateStep,
            })
          );
          return prevCount;
        });
      } else {
        setIsDirty(false);
      }
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('language_select_view:data_not'),
        })
      );
    }
    setIsDialogOpenJump(false);
  };

  const CancelSubmission = async (): Promise<void> => {
    dispatch(
      consultantPrevStateAction({
        id: verificationId,
        consultantId,
        basicInfo: consultant.verification?.basicInfo,
        certificateInfoList: consultant.verification?.certificateInfoList,
        companyResourceInfoList: consultant.verification?.companyResourceInfoList,
        educationInfoList: consultant.verification?.educationInfoList,
        honorInfoList: consultant.verification?.honorInfoList,
        languageInfoList: consultant.verification?.languageInfoList,
        projectInfoList: consultant.verification?.projectInfoList,
        workInfoList: consultant.verification?.workInfoList,
        settings: consultant.verification?.settings,
        otherInfo: consultant.verification?.otherInfo,
        consultantType: 'PERSONAL',
        status: consultant.verification?.status,
      })
    );

    setIsDialogOpenJump(false);
    setStatusConst(prevCount => {
      const stateStep = prevCount;
      dispatch(
        activeConsultantVerificationStep({
          ConsultantVerificationStep: stateStep,
        })
      );
      return prevCount;
    });
  };

  const handleVerificationPrevious = async (): Promise<void> => {
    setStatusConst('PERSONAL_STEP');
    isSubmit();
  };

  const handleVerificationUpdate = async (): Promise<void> => {
    setStatusConst('CAPACITY_STEP');
    isSubmit();
  };

  statusConst;

  const isSubmit = async (statusTypeStatus?: consultantRegisterStepType): Promise<void> => {
    if (statusTypeStatus) {
      setStatusConst(statusTypeStatus);
    }
    if (isDirty) {
      trigger('languageInfoList');
      trigger('personalHonorList');
      trigger('consultantCertificateList');

      setIsDialogOpenJump(true);
    } else {
      dispatch(
        consultantPrevStateAction({
          id: verificationId,
          consultantId,
          basicInfo: consultant.verification?.basicInfo,
          certificateInfoList: consultant.verification?.certificateInfoList,
          companyResourceInfoList: consultant.verification?.companyResourceInfoList,
          educationInfoList: consultant.verification?.educationInfoList,
          honorInfoList: consultant.verification?.honorInfoList,
          languageInfoList: consultant.verification?.languageInfoList,
          projectInfoList: consultant.verification?.projectInfoList,
          workInfoList: consultant.verification?.workInfoList,
          settings: consultant.verification?.settings,
          otherInfo: consultant.verification?.otherInfo,
          consultantType: 'PERSONAL',
          status: consultant.verification?.status,
        })
      );

      setStatusConst(prevCount => {
        const stateStep = prevCount;
        dispatch(
          activeConsultantVerificationStep({
            ConsultantVerificationStep: stateStep,
          })
        );
        return prevCount;
      });
    }
  };

  const handleLanguageInfoAdd = () => {
    append(createConsultantLanguageInfo());
  };

  const handlePersonalHonorAdd = () => {
    addPersonalHonor(createConsultantPersonalHonors());
  };

  const handleCertificatesAdd = () => {
    addCertificates(createConsultantCertificate());
  };

  const handleLanguageInfoDelete = (index?: number | number[]) => {
    remove(index);
  };

  const handlePersonalHonorDelete = (index?: number | number[]) => {
    removePersonalHonor(index);
  };

  const handleCertificatesDelete = (index?: number | number[]) => {
    removeCertificates(index);
  };

  useEffect(() => {
    if (consultant.verification && consultant.verification.languageInfoList) {
      setValue('languageInfoList', consultant.verification.languageInfoList);
    }
  }, [consultant.verification?.languageInfoList]);

  useEffect(() => {
    if (consultant.verification && consultant.verification.certificateInfoList) {
      setValue('consultantCertificateList', consultant.verification.certificateInfoList);
    }
  }, [consultant.verification?.certificateInfoList]);

  useEffect(() => {
    if (consultant.verification && consultant.verification.honorInfoList) {
      setValue('personalHonorList', consultant.verification.honorInfoList);
    }
  }, [consultant.verification?.honorInfoList]);
  useEffect(() => {
    if (consultant.verification && consultant.verification.otherInfo) {
      setValue('otherInfo', consultant.verification.otherInfo);
    }
  }, [consultant.verification?.otherInfo]);

  return (
    <>
      {consultantType === 'PERSONAL' && (
        <ConsultantStepBar handleStepUpdate={isSubmit}></ConsultantStepBar>
      )}
      <Grid id="basicView" item xs={12}>
        <Paper className={classes.formContainer}>
          <Grid container spacing={2}>
            <MyIntroductionInputView
              control={control}
              errors={errors}
              basicInfo={consultant.verification?.basicInfo}
              setValue={setValue}
              otherInfo={consultant.verification?.otherInfo}
            />
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container spacing={2}>
            {languageInfoList.length > 0
              ? languageInfoList.map((field, index) => {
                  return (
                    <LanguageInputView
                      key={field.id}
                      index={index}
                      control={control}
                      errors={errors}
                      // setValue={setValue}
                      remove={handleLanguageInfoDelete}
                    />
                  );
                })
              : append(createConsultantLanguageInfo())}
          </Grid>

          <Grid item container xs={12} className={classes.bottomButton}>
            <Grid item xs={12}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.btnMargin}
                onClick={handleLanguageInfoAdd}
              >
                <PlaylistAddIcon fontSize="small" />
                {t('language_select_view:add_language')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container spacing={2}>
            {personalHonorList.length > 0
              ? personalHonorList.map((field, index) => {
                  return (
                    <PersonalHonors
                      key={field.id}
                      index={index}
                      control={control}
                      errors={errors}
                      // setValue={setValue}
                      remove={handlePersonalHonorDelete}
                    />
                  );
                })
              : addPersonalHonor(createConsultantPersonalHonors())}
          </Grid>
          <Grid item container xs={12} className={classes.bottomButton}>
            <Grid item xs={12}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.btnMargin}
                onClick={handlePersonalHonorAdd}
              >
                <PlaylistAddIcon fontSize="small" />
                {t('language_select_view:add_personal_honors')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container spacing={2}>
            {consultantCertificateList.length > 0
              ? consultantCertificateList.map((field, index) => {
                  return (
                    <CertificateInputView
                      key={field.id}
                      index={index}
                      control={control}
                      errors={errors}
                      deleteFile={EditInformationDialog}
                      remove={handleCertificatesDelete}
                    />
                  );
                })
              : addCertificates(createConsultantCertificate())}
          </Grid>
          <Grid item container xs={12} className={classes.bottomButton}>
            <Grid item xs={12}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.btnMargin}
                onClick={handleCertificatesAdd}
              >
                <PlaylistAddIcon fontSize="small" />
                {t('language_select_view:add_certificate')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Grid item container xs={12} className={classes.bothButton}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleVerificationPrevious}
          >
            <SkipPreviousIcon fontSize="small" />
            {t('language_select_view:back')}
          </Button>

          <Button
            type="button"
            variant="contained"
            color="primary"
            // disabled={!(isDirty && !!dirtyFields.basicInfo)}
            onClick={(): void => {
              handleVerificationUpdate();
            }}
          >
            {t('language_select_view:next_step')}
            <SkipNextIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <EditInformationJumpDialog
        isOpen={isDialogOpenJump}
        handleEdit={() => {
          EditInformationDialog();
        }}
        handleDialogClose={() => {
          CancelSubmission();
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  consultant?: Consultant;
}

export default withStyles(styles)(LanguageSelectView);
