import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Divider,
  Grid,
  Paper,
  StyleRules,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../../actions/consultant/consultantVerificationActions';
import {
  ConsultantBasicInformationEditingDialog,
  EditInformationJumpDialog,
  RegistrationPolicyDialog,
} from '../../../components/Dialog';
import { AppState } from '../../../reducers';
import { consultantPrevStateAction } from '../../../reducers/consultantReducer';
import { showNotificationAction } from '../../../reducers/notificationReducer';
import { activeConsultantVerificationStep } from '../../../reducers/otherStatus';
import { createConsultantBasicInfo } from '../consultant.model';
import PersonalInfoInputView from './PersonalInfoInputView';
import Stepper from './VerificationStepperView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    formContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    infoTitle: {
      marginBottom: theme.spacing(1),
    },
    bottomButton: {
      margin: '0 30px',
    },
    contentCenter: {
      width: '100%',
    },
  });

const VerificationPersonal_info_view = ({ classes }: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('personal_info_view');
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const consultantId = consultant.id;
  const verificationId = consultant?.verification?.id;
  const [consultantType, setConsultantType] = useState<string>(
    consultant.verification?.consultantType || 'PERSONAL'
  );
  const verificationStatus = consultant.verification?.status;
  setConsultantType;
  const [statusConst, setStatusConst] = useState<consultantRegisterStepType>();
  const [promptText, setPromptText] = useState<boolean>(false);
  const { setValue, control, getValues, trigger, reset } = useForm({
    defaultValues: {
      basicInfo: createConsultantBasicInfo(),
    },
    mode: 'onChange',
  });
  const { isDirty = false, errors, isValid } = useFormState({ control });

  const setIsDirty = useCallback(
    isDirty => {
      reset({ isDirty });
    },
    [reset]
  );
  const [basicInfo, setBasicInfo] = useState<ConsultantBasicInfo>(getValues('basicInfo'));
  const [isDialogOpenJump, setIsDialogOpenJump] = useState<boolean>(false);
  const [isDialogBasicInfo, setIsDialogBasicInfo] = useState<boolean>(false);
  const [isEditBasicInfoMode, setEditBasicInfoMode] = useState<boolean>(false);

  const editInformationDialog = async (isDeleteFile?: boolean): Promise<void> => {
    if (isValid) {
      setValue('basicInfo.birthday', new Date(String(getValues('basicInfo.birthday'))));
      if (verificationStatus == 'SUBMITTED' || verificationStatus == 'VERIFIED') {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            basicInfo: getValues('basicInfo'),
          })
        );
      } else {
        dispatch(
          saveConsultantVerification({
            id: verificationId,
            consultantType: 'PERSONAL',
            basicInfo: getValues('basicInfo'),
            status: 'PERSONAL_REGISTERED',
          })
        );
      }
      if (!isEditBasicInfoMode && !isDeleteFile) {
        if (statusConst) {
          dispatch(
            activeConsultantVerificationStep({
              ConsultantVerificationStep: statusConst,
            })
          );
        } else {
          dispatch(
            activeConsultantVerificationStep({
              ConsultantVerificationStep: 'PERSONAL_STEP',
            })
          );
        }
      } else {
        setIsDirty(false);
      }
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: t('education_view:data_not'),
        })
      );
    }
    setIsDialogOpenJump(false);
  };

  const cancelSubmission = async (): Promise<void> => {
    dispatch(
      consultantPrevStateAction({
        id: verificationId,
        consultantId,
        basicInfo: consultant.verification?.basicInfo,
        certificateInfoList: consultant.verification?.certificateInfoList,
        companyResourceInfoList: consultant.verification?.companyResourceInfoList,
        educationInfoList: consultant.verification?.educationInfoList,
        honorInfoList: consultant.verification?.honorInfoList,
        languageInfoList: consultant.verification?.languageInfoList,
        projectInfoList: consultant.verification?.projectInfoList,
        workInfoList: consultant.verification?.workInfoList,
        settings: consultant.verification?.settings,
        otherInfo: consultant.verification?.otherInfo,
        consultantType: 'PERSONAL',
        status: consultant.verification?.status,
      })
    );
    setIsDialogOpenJump(false);
    if (statusConst) {
      dispatch(
        activeConsultantVerificationStep({
          ConsultantVerificationStep: statusConst,
        })
      );
    } else {
      dispatch(
        activeConsultantVerificationStep({
          ConsultantVerificationStep: 'PERSONAL_STEP',
        })
      );
    }
  };

  const handleVerificationUpdate = async (): Promise<void> => {
    isSubmit();
  };

  const isSubmit = async (statusTypeStatus?: consultantRegisterStepType): Promise<void> => {
    if (statusTypeStatus) {
      setStatusConst(statusTypeStatus);
    }
    if (isDirty) {
      trigger('basicInfo');
      setIsDialogOpenJump(true);
    } else {
      dispatch(
        consultantPrevStateAction({
          id: verificationId,
          consultantId,
          basicInfo: consultant.verification?.basicInfo,
          certificateInfoList: consultant.verification?.certificateInfoList,
          companyResourceInfoList: consultant.verification?.companyResourceInfoList,
          educationInfoList: consultant.verification?.educationInfoList,
          honorInfoList: consultant.verification?.honorInfoList,
          languageInfoList: consultant.verification?.languageInfoList,
          projectInfoList: consultant.verification?.projectInfoList,
          workInfoList: consultant.verification?.workInfoList,
          settings: consultant.verification?.settings,
          otherInfo: consultant.verification?.otherInfo,
          consultantType: 'PERSONAL',
          status: consultant.verification?.status,
        })
      );
      setStatusConst(prevCount => {
        if (prevCount) {
          dispatch(
            activeConsultantVerificationStep({
              ConsultantVerificationStep: prevCount,
            })
          );
        } else {
          dispatch(
            activeConsultantVerificationStep({
              ConsultantVerificationStep: 'PERSONAL_STEP',
            })
          );
        }
        return prevCount;
      });
    }
  };

  useEffect(() => {
    if (consultant && consultant.verification && consultant.verification.basicInfo) {
      setValue('basicInfo', consultant.verification.basicInfo);
      setBasicInfo(consultant.verification.basicInfo);
    }
  }, [consultant.verification?.basicInfo]);

  useEffect(() => {
    verificationId ? setPromptText(false) : setPromptText(true);
  }, [verificationId]);

  return (
    <>
      {consultantType === 'PERSONAL' && <Stepper handleStepUpdate={isSubmit}></Stepper>}
      <Grid id="basicView" item xs={12}>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('personal_info_view:basic_information')}
            </Typography>
          </Grid>
          <Divider className={classes.infoTitle} />
          <Grid container spacing={2}>
            <PersonalInfoInputView
              verificationId={verificationId}
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              basicInfo={basicInfo}
              deleteFile={editInformationDialog}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            {/* {verificationStatus == 'SUBMITTED' || verificationStatus == 'VERIFIED' ? (
              <Button
                className={classes.bottomButton}
                type="button"
                variant="contained"
                color="primary"
                onClick={(): void => {
                  setIsDialogBasicInfo(true);
                  setEditBasicInfoMode(true);
                }}
              >
                编辑基本信息
                <EditIcon fontSize="small"></EditIcon>
              </Button>
            ) : (
              ''
            )} */}
            <Button
              className={classes.bottomButton}
              type="button"
              variant="contained"
              color="primary"
              onClick={(): void => {
                handleVerificationUpdate();
              }}
            >
              {t('personal_info_view:next_step')}
              <SkipNextIcon fontSize="small"></SkipNextIcon>
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <ConsultantBasicInformationEditingDialog
        isOpen={isDialogBasicInfo}
        handleEdit={editInformationDialog}
        handleDialogClose={() => {
          setIsDialogBasicInfo(false);
          setEditBasicInfoMode(false);
        }}
      >
        <PersonalInfoInputView
          className={classes.contentCenter}
          verificationId={verificationId}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          basicInfo={basicInfo}
          deleteFile={editInformationDialog}
          editBasicInfoMode={isEditBasicInfoMode}
        />
      </ConsultantBasicInformationEditingDialog>
      <EditInformationJumpDialog
        isOpen={isDialogOpenJump}
        handleEdit={editInformationDialog}
        handleDialogClose={() => {
          cancelSubmission();
        }}
      />
      {localStorage.getItem('verificationConsultantPolicyPrompt') !== 'true' && (
        <RegistrationPolicyDialog
          isOpen={promptText}
          handleDialogClose={() => {
            setPromptText(false);
          }}
        />
      )}
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(VerificationPersonal_info_view);
