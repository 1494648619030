import React from 'react';

import ConsultantLayout from '../../layout/ConsultantLayout';
import VerificationView from '../../views/consultant/verification';

const ConsultantPage = (): React.ReactElement => {
  return (
    <>
      <ConsultantLayout verificationHide={true}>
        <VerificationView />
      </ConsultantLayout>
    </>
  );
};

export default ConsultantPage;
