import React, { ReactElement } from 'react';
import {
  Control, //类型
  Controller,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StyleRules,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from 'i18next';

// import TextFieldCustom from '../../../components/TextField';
import debug from '../../../utils/debug';
import educationBackground from '../../../utils/educationBackground';
import specializedBackground from '../../../utils/specialized';

const styles = (): StyleRules =>
  createStyles({
    submit: {
      textAlign: 'center',
    },
    imageGird: {
      position: 'relative',
    },
    imageButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    },
    borderButton: {
      width: '100%',
      margin: '20px 0',
    },
    errColor: {
      color: 'red',
    },
  });

const EducationInputView = ({
  classes,
  index,
  control,
  remove,
  errors,
  summaryMode = false,
}: Props): ReactElement => {
  debug('Load education input view');
  const { t } = useTranslation();
  i18n.loadNamespaces('EducationInputView');
  return (
    <>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={!summaryMode ? t('education_input_view:school_name_tooltip') : ''}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus={false}
                    label={t('education_input_view:school_name')}
                    error={!!errors?.educationInfoList?.[index]?.schoolName}
                    disabled={summaryMode}
                    helperText={
                      !!errors?.educationInfoList?.[index]?.schoolName &&
                      t('education_input_view:school_not_empty')
                    }
                    {...field}
                  />
                </Tooltip>
              </>
            )}
            name={`educationInfoList.${index}.schoolName`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl margin="normal" variant="outlined" fullWidth>
                  <InputLabel id="label-education">{t('education_input_view:diploma')}</InputLabel>
                  <Select
                    error={!!errors?.educationInfoList?.[index]?.education}
                    labelId="label-education"
                    id="education"
                    label="学历"
                    fullWidth
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                  >
                    {educationBackground.map((edbg, index) => {
                      return (
                        <MenuItem key={index} value={edbg.value}>
                          {i18n.language == 'enUS' ? edbg.value : edbg.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors?.educationInfoList?.[index]?.education && (
                    <FormHelperText className={classes.errColor}>
                      {t('education_input_view:education_not_empty')}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
            name={`educationInfoList.${index}.education`}
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl margin="normal" variant="outlined" fullWidth>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={
                    !summaryMode
                      ? t('education_input_view:professional_classification_tooltip')
                      : ''
                  }
                >
                  <Autocomplete
                    defaultValue={null}
                    id="combo-box-demo"
                    options={specializedBackground}
                    getOptionLabel={option => option.label}
                    getOptionDisabled={option => option.disabled == true}
                    fullWidth
                    disabled={summaryMode}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          label={t('education_input_view:professional_classification')}
                          variant="outlined"
                        />
                      );
                    }}
                    value={
                      specializedBackground.find(option => option.value === field.value) || null
                    } // 设置 Autocomplete 的 value 属性
                    onChange={(_, newValue) => {
                      field.onChange(newValue?.value || null); // 更新 React Hook Form 字段值
                    }}
                  />
                </Tooltip>
              </FormControl>
            )}
            name={`educationInfoList.${index}.majorType`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={!summaryMode ? t('education_input_view:professional_name_tooltip') : ''}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus={false}
                    label={t('education_input_view:professional_name')}
                    disabled={summaryMode}
                    error={!!errors?.educationInfoList?.[index]?.academicDirections}
                    helperText={
                      errors?.educationInfoList?.[index]?.academicDirections &&
                      t('education_input_view:major_not_empty')
                    }
                    {...field}
                  />
                </Tooltip>
              </>
            )}
            name={`educationInfoList.${index}.academicDirections`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
      </Grid>

      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      error={!!errors?.educationInfoList?.[index]?.atSchoolDateRangeStart}
                      variant="inline"
                      format="yyyy-MM"
                      views={['year', 'month']}
                      id="date-picker-start"
                      label={t('education_input_view:admission_date')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={summaryMode}
                      readOnly={summaryMode}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.educationInfoList?.[index]?.atSchoolDateRangeStart && (
                    <FormHelperText className={classes.errColor}>
                      {t('education_input_view:enrollment_not_empty')}
                    </FormHelperText>
                  )}
                </>
              );
            }}
            name={`educationInfoList.${index}.atSchoolDateRangeStart`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    format="yyyy-MM"
                    views={['year', 'month']}
                    id="date-picker-end"
                    label={t('education_input_view:graduation_time')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                    value={field.value ?? null}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
            name={`educationInfoList.${index}.atSchoolDateRangeEnd`}
            control={control}
          ></Controller>
        </Grid>
      </Grid>

      {!summaryMode && remove && (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                remove(index);
              }}
            >
              {t('education_input_view:delete')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Divider className={classes.borderButton} />
    </>
  );
};
// 导出类型
export type ConsultantEducationInfoControl = Control<{
  educationInfoList: ConsultantEducationInfo[];
}>;
export type ConsultantEducationInfoSetValue = UseFormSetValue<{
  educationInfoList: ConsultantEducationInfo[];
}>;
export type ConsultantEducationInfoErrors = FieldErrors<{
  educationInfoList: ConsultantEducationInfo[];
}>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  index: number;
  control: ConsultantEducationInfoControl;
  setValue?: ConsultantEducationInfoSetValue;
  errors?: ConsultantEducationInfoErrors;
  remove?: UseFieldArrayRemove;
  summaryMode?: boolean;
}

export default withStyles(styles)(EducationInputView);
