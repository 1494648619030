import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  createStyles,
  Grid,
  Paper,
  StyleRules,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { Alert } from '@material-ui/lab';
import i18n from 'i18next';

import { saveConsultantVerification } from '../../../actions/consultant/consultantVerificationActions';
import { ConsultantConfirmsCompletionOfRegistrationDialog } from '../../../components/Dialog';
import { consultantPrevStateAction } from '../../../reducers/consultantReducer';
import { activeConsultantVerificationStep } from '../../../reducers/otherStatus';
import CertificateInputView, { ConsultantCertificateControl } from './CertificateInputView';
import EducationInputView, { ConsultantEducationInfoControl } from './EducationInputView';
import LanguagesInputView, { ConsultantLanguageInfoControl } from './LanguagesInputView';
import MyIntroductionInputView from './MyIntroductionInputView';
import PersonalHonors, { ConsultantPersonalHonorControl } from './PersonalHonorsView';
import PersonalInfoInputView, { ConsultantBasicInfoControl } from './PersonalInfoInputView';
import ProjectExperienceInputView, {
  ConsultantProjectInfoControl,
} from './ProjectExperiencesInputView';
import SetingsInputView, { ConsultantSettingsInfoControl } from './SetingsInputView';
import WorkInputView, { ConsultantWorkInfoControl } from './WorkInputView';
const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    formContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    submit: {
      textAlign: 'center',
    },
    infoTitle: {
      marginBottom: theme.spacing(1),
    },
    form: {
      width: '100%',
    },
    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      justifyContent: 'end',
    },
  });

const SummaryView = ({ classes, consultant }: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('SummaryView');
  const dispatch = useDispatch();
  const consultantId = consultant.id;
  const verification = consultant?.verification;
  const verificationId = verification?.id;
  const verificationStatus = verification?.status;
  const { setValue, control, getValues } = useForm({
    defaultValues: {
      basicInfo: verification?.basicInfo as ConsultantBasicInfo,
      educationInfoList: verification?.educationInfoList as ConsultantEducationInfo[],
      workInfoList: verification?.workInfoList as ConsultantWorkInfo[],
      projectInfoList: verification?.projectInfoList as ConsultantProjectInfo[],
      languageInfoList: verification?.languageInfoList as ConsultantLanguageInfo[],
      settings: verification?.settings as ConsultantSettingsInfo,
      otherInfo: verification?.otherInfo,
      personalHonorList: verification?.honorInfoList as ConsultantPersonalHonorInfo[],
      consultantCertificateList: verification?.certificateInfoList as ConsultantCertificateInfo[],
    },
    mode: 'onChange',
  });
  const [basicInfo] = useState<ConsultantBasicInfo>(verification?.basicInfo as ConsultantBasicInfo);
  const [otherInfo] = useState<ConsultantOtherInfo>(verification?.otherInfo as ConsultantOtherInfo);

  const [isDialogOpenJump, setIsDialogOpenJump] = useState<boolean>(true);
  const { fields: educationInfoList } = useFieldArray({
    control,
    name: 'educationInfoList',
  });
  const { fields: workInfoList } = useFieldArray({
    control,
    name: 'workInfoList',
  });
  const { fields: projectInfoList } = useFieldArray({
    control,
    name: 'projectInfoList',
  });
  const { fields: languageInfoList } = useFieldArray({
    control,
    name: 'languageInfoList',
  });
  const { fields: personalHonorList } = useFieldArray({
    control,
    name: 'personalHonorList',
  });
  const { fields: consultantCertificateList } = useFieldArray({
    control,
    name: 'consultantCertificateList',
  });

  const handleEditConsultantInformation = async (): Promise<void> => {
    dispatch(
      activeConsultantVerificationStep({
        ConsultantVerificationStep: 'NONE',
      })
    );
  };

  const handleVerificationPrevious = async (): Promise<void> => {
    dispatch(
      consultantPrevStateAction({
        id: verificationId,
        consultantId,
        basicInfo: consultant.verification?.basicInfo,
        certificateInfoList: consultant.verification?.certificateInfoList,
        companyResourceInfoList: consultant.verification?.companyResourceInfoList,
        educationInfoList: consultant.verification?.educationInfoList,
        honorInfoList: consultant.verification?.honorInfoList,
        languageInfoList: consultant.verification?.languageInfoList,
        projectInfoList: consultant.verification?.projectInfoList,
        workInfoList: consultant.verification?.workInfoList,
        settings: consultant.verification?.settings,
        otherInfo: consultant.verification?.otherInfo,
        consultantType: 'PERSONAL',
        status: consultant.verification?.status,
      })
    );
    dispatch(
      activeConsultantVerificationStep({
        ConsultantVerificationStep: 'CAPACITY_STEP',
      })
    );
  };

  const handleVerificationUpdate = async (): Promise<void> => {
    dispatch(
      saveConsultantVerification({
        id: verificationId,
        consultantType: 'PERSONAL',
        status: 'SUBMITTED',
      })
    );
    dispatch(
      activeConsultantVerificationStep({
        ConsultantVerificationStep: 'SUMMARY_STEP',
      })
    );
  };

  useEffect(() => {
    if (consultant && consultant.verification) {
      if (consultant.verification.basicInfo)
        setValue('basicInfo', consultant.verification?.basicInfo);
      if (consultant.verification.educationInfoList)
        setValue('educationInfoList', consultant.verification?.educationInfoList);
      if (consultant.verification.workInfoList) {
        const workInfoData = JSON.stringify(consultant.verification.workInfoList);
        const data = JSON.parse(workInfoData);
        data.forEach((workInfo: ConsultantWorkInfo) => {
          if (typeof workInfo.professionalClass == 'string')
            workInfo.professionalClass = JSON.parse(workInfo.professionalClass);
          if (typeof workInfo.industryClass == 'string')
            workInfo.industryClass = JSON.parse(workInfo.industryClass);
        });
        setValue('workInfoList', data);
      }

      if (consultant.verification.projectInfoList)
        setValue('projectInfoList', consultant.verification.projectInfoList);
      if (consultant.verification.honorInfoList) {
        setValue('personalHonorList', consultant.verification.honorInfoList);
      }
      if (consultant.verification.certificateInfoList)
        setValue('consultantCertificateList', consultant.verification.certificateInfoList);
      if (consultant.verification.languageInfoList)
        setValue('languageInfoList', consultant.verification.languageInfoList);
      if (consultant.verification.otherInfo)
        setValue('otherInfo', consultant.verification.otherInfo);
      if (consultant.verification.settings) setValue('settings', consultant.verification.settings);
    }
  }, [consultant.verification]);
  useEffect(() => {
    consultant.verification?.status == 'SUBMITTED' ? setIsDialogOpenJump(false) : '';
  }, [consultant.verification?.status]);

  return (
    <>
      <Helmet title="顾问信息预览"></Helmet>
      <Grid id="basicView" item xs={12}>
        <Paper className={classes.formContainer}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography align="left" variant="h6" className={classes.infoTitle}>
                {t('summary_view:consultant_certification_preview')}
              </Typography>
            </Grid>
            {verificationStatus === 'SUBMITTED' && (
              <Grid item xs={12}>
                <Alert severity="info">
                  {t('summary_view:msg')} <b>info@fintegrity.cn</b>
                </Alert>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('summary_view:basic_information')}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <PersonalInfoInputView
              control={(control as unknown) as ConsultantBasicInfoControl}
              setValue={setValue}
              getValues={getValues}
              basicInfo={basicInfo}
              summaryMode={true}
            />
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('summary_view:educational_information')}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {educationInfoList.map((field, index) => {
              return (
                <EducationInputView
                  key={field.id}
                  index={index}
                  control={(control as unknown) as ConsultantEducationInfoControl}
                  setValue={setValue}
                  summaryMode={true}
                />
              );
            })}
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('summary_view:work_information')}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {workInfoList.map((field, index) => {
              return (
                <WorkInputView
                  key={field.id}
                  index={index}
                  control={(control as unknown) as ConsultantWorkInfoControl}
                  setValue={setValue}
                  summaryMode={true}
                />
              );
            })}
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('summary_view:project_information')}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {projectInfoList.map((field, index) => {
              return (
                <ProjectExperienceInputView
                  key={field.id}
                  index={index}
                  control={(control as unknown) as ConsultantProjectInfoControl}
                  setValue={setValue}
                  summaryMode={true}
                />
              );
            })}
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('summary_view:other_capabilities')}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <MyIntroductionInputView
              control={(control as unknown) as ConsultantLanguageInfoControl}
              summaryMode={true}
              basicInfo={basicInfo}
              otherInfo={otherInfo}
              setValue={setValue}
            />
          </Grid>
          <Grid container spacing={2}>
            {languageInfoList.map((field, index) => {
              return (
                <LanguagesInputView
                  key={field.id}
                  index={index}
                  control={(control as unknown) as ConsultantLanguageInfoControl}
                  setValue={setValue}
                  summaryMode={true}
                />
              );
            })}
          </Grid>
          <Grid container spacing={2}>
            {personalHonorList.map((field, index) => {
              return (
                <PersonalHonors
                  key={field.id}
                  index={index}
                  control={(control as unknown) as ConsultantPersonalHonorControl}
                  // setValue={setValue}
                  summaryMode={true}
                />
              );
            })}
          </Grid>
          <Grid container spacing={2}>
            {consultantCertificateList.map((field, index) => {
              return (
                <CertificateInputView
                  key={field.id}
                  index={index}
                  control={(control as unknown) as ConsultantCertificateControl}
                  // setValue={setValue}
                  summaryMode={true}
                />
              );
            })}
          </Grid>
        </Paper>
        <Paper className={classes.formContainer}>
          <Grid container item xs={12}>
            <Typography align="left" variant="h6" className={classes.infoTitle}>
              {t('summary_view:other_settings')}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <SetingsInputView
              control={(control as unknown) as ConsultantSettingsInfoControl}
              summaryMode={true}
            />
          </Grid>
        </Paper>
        {verificationStatus == 'SUBMITTED' || verificationStatus == 'VERIFIED' ? (
          <div className={classes.bottomButton}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={(): void => {
                handleEditConsultantInformation();
              }}
            >
              编辑
              <EditIcon fontSize="small" />
            </Button>
          </div>
        ) : (
          ''
        )}
        {verificationStatus !== 'SUBMITTED' && verificationStatus !== 'VERIFIED' && (
          <Grid
            item
            container
            xs={12}
            className={classes.bottomButton}
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={(): void => {
                  handleVerificationPrevious();
                }}
              >
                <SkipPreviousIcon fontSize="small" />
                {t('summary_view:back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                // disabled={!(isDirty && !!dirtyFields.basicInfo)}
                onClick={(): void => {
                  handleVerificationUpdate();
                }}
              >
                <DoneIcon fontSize="small" />
                {t('summary_view:confirm_submission')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ConsultantConfirmsCompletionOfRegistrationDialog
        isOpen={
          isDialogOpenJump &&
          verificationStatus !== 'SUBMITTED' &&
          verificationStatus !== 'VERIFIED'
        }
        handleDialogClose={() => {
          setIsDialogOpenJump(false);
        }}
      />
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  consultant: Consultant;
}

export default withStyles(styles)(SummaryView);
