import React, { ReactElement } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  StyleRules,
  TextField,
  Theme,
  Tooltip,
  WithStyles,
  withStyles,
  Zoom,
} from '@material-ui/core';
import i18n from 'i18next';

import debug from '../../../utils/debug';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    submit: {
      textAlign: 'center',
    },
    imageGird: {
      position: 'relative',
    },
    imageButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    },
    text: {
      margin: 'auto 0',
    },
    mgtop: {
      marginTop: '20px',
    },
    infoTitle: {
      marginBottom: theme.spacing(1),
    },
    moneyHeight: {
      lineHeight: '64px',
      fontSize: '30px',
      paddingLeft: '10px',
    },
    consultantResume: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '1rem',
    },
    consultantResumeName: {
      marginRight: '1rem',
    },
    uploadBtn: {
      marginTop: '1rem',
    },
  });

const SetingsInputView = ({
  classes,
  control,
  errors,
  // stateSettings,
  // setOtherValue,
  // getValues,
  summaryMode = false,
}: Props): ReactElement => {
  debug('Load setOther input view');
  const { t } = useTranslation();
  i18n.loadNamespaces('SetingsInputView');
  // const [stateData, setStateData] = useState<workScenarioSelect>(
  //   getValues('settings.workScenario')
  // );
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setStateData({ ...stateData, [event.target.name]: event.target.checked });
  //   if (event.target.name == 'onsite') {
  //     setOtherValue('settings.workScenario.onsite', event.target.checked, {
  //       shouldDirty: true,
  //     });
  //   }
  //   if (event.target.name == 'remote') {
  //     setOtherValue('settings.workScenario.remote', event.target.checked, {
  //       shouldDirty: true,
  //     });
  //   }
  // };

  return (
    <>
      <Grid container item xs={12} className={classes.mgtop} justifyContent="space-between">
        <Grid container item xs={12}>
          <FormLabel component="legend">{t('settings_input_view:consultant_fees')}</FormLabel>
        </Grid>
        <Grid container item xs={5}>
          <Grid item>
            <Controller
              render={({ field }) => (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={!summaryMode ? t('settings_input_view:hour_fee_tooltip') : ''}
                >
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    margin="normal"
                    autoFocus={false}
                    label={t('settings_input_view:hour_fee')}
                    disabled={summaryMode}
                    error={!!errors?.settings?.expectedFeePerHour}
                    helperText={
                      errors?.settings?.expectedFeePerHour &&
                      t('settings_input_view:hour_fee_not_empty')
                    }
                    {...field}
                  />
                </Tooltip>
              )}
              name={`settings.expectedFeePerHour`}
              control={control}
            ></Controller>
          </Grid>
          <Grid item className={classes.moneyHeight}>
            {/* ￥ */}
          </Grid>
        </Grid>
        <Grid container item xs={5}>
          <Grid item>
            <Controller
              render={({ field }) => (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={!summaryMode ? t('settings_input_view:month_fee_tooltip') : ''}
                >
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    margin="normal"
                    autoFocus={false}
                    label={t('settings_input_view:month_fee')}
                    disabled={summaryMode}
                    error={!!errors?.settings?.expectedFeePerMonth}
                    helperText={
                      errors?.settings?.expectedFeePerMonth &&
                      t('settings_input_view:m0nth_fee_not_empty')
                    }
                    {...field}
                  />
                </Tooltip>
              )}
              name={`settings.expectedFeePerMonth`}
              control={control}
            ></Controller>
          </Grid>
          <Grid item className={classes.moneyHeight}>
            {/* ￥ */}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.mgtop} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl
                margin="normal"
                variant="outlined"
                error={!!errors?.settings?.findingJob}
                fullWidth
              >
                <FormLabel component="legend">
                  {t('settings_input_view:finding_projects')}
                </FormLabel>
                <RadioGroup aria-label="findingJob" {...field}>
                  <FormControlLabel
                    value="FindingJob"
                    disabled={summaryMode}
                    control={<Radio />}
                    label={t('settings_input_view:finding_job')}
                  />
                  <FormControlLabel
                    value="notFindingJob"
                    disabled={summaryMode}
                    control={<Radio />}
                    label={t('settings_input_view:not_finding_job')}
                  />
                </RadioGroup>
                {errors?.settings?.findingJob && (
                  <FormHelperText>{t('settings_input_view:finding_not_empty')}</FormHelperText>
                )}
              </FormControl>
            )}
            name={`settings.findingJob`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <FormLabel component="legend">{t('settings_input_view:work_scenario')}</FormLabel>
            <FormGroup>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    disabled={summaryMode}
                    control={<Checkbox {...field} checked={field.value} name="onsite" />}
                    label={t('settings_input_view:onsite')}
                  />
                )}
                name={`settings.workScenario.onsite`}
                control={control}
              ></Controller>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    disabled={summaryMode}
                    control={<Checkbox {...field} checked={field.value} name="remote" />}
                    label={t('settings_input_view:remote')}
                  />
                )}
                name={`settings.workScenario.remote`}
                control={control}
              ></Controller>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.mgtop} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl
                margin="normal"
                variant="outlined"
                error={!!errors?.settings?.workType}
                fullWidth
              >
                <FormLabel component="legend">{t('settings_input_view:working_mode')}</FormLabel>
                <RadioGroup {...field} aria-label="quiz">
                  <FormControlLabel
                    disabled={summaryMode}
                    value="FullTimeJob"
                    control={<Radio />}
                    label={t('settings_input_view:fulltime_job')}
                  />
                  <FormControlLabel
                    disabled={summaryMode}
                    value="notFullTimeJob"
                    control={<Radio />}
                    label={t('settings_input_view:not_fulltime_job')}
                  />
                </RadioGroup>
                {errors?.settings?.workType && (
                  <FormHelperText>{t('settings_input_view:working_mode_not_empty')}</FormHelperText>
                )}
              </FormControl>
            )}
            rules={{ required: true }}
            name={`settings.workType`}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl
                margin="normal"
                variant="outlined"
                error={!!errors?.settings?.workDossier}
                fullWidth
              >
                <FormLabel component="legend">
                  {t('settings_input_view:disclose_file_information')}
                </FormLabel>
                <RadioGroup {...field} aria-label="quiz">
                  <FormControlLabel
                    value="Public"
                    disabled={summaryMode}
                    control={<Radio />}
                    label={t('settings_input_view:public')}
                  />
                  <FormControlLabel
                    value="notPublic"
                    disabled={summaryMode}
                    control={<Radio />}
                    label={t('settings_input_view:not_public')}
                  />
                </RadioGroup>
                {errors?.settings?.workDossier && (
                  <FormHelperText>
                    {t('settings_input_view:file_information_not_empty')}
                  </FormHelperText>
                )}
              </FormControl>
            )}
            name={`settings.workDossier`}
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
    </>
  );
};

export type ConsultantSettingsInfoControl = Control<{
  settings: ConsultantSettingsInfo;
}>;
export type ConsultantSettingsInfoSetValue = UseFormSetValue<{
  settings: ConsultantSettingsInfo;
}>;
export type ConsultantSettingsInfoGetValue = UseFormGetValues<{
  settings: ConsultantSettingsInfo;
}>;
export type ConsultantSettingsInfoErrors = FieldErrors<{
  settings: ConsultantSettingsInfo;
}>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  control: ConsultantSettingsInfoControl;
  setOtherValue?: ConsultantSettingsInfoSetValue;
  errors?: ConsultantSettingsInfoErrors;
  summaryMode?: boolean;
  getValues?: ConsultantSettingsInfoGetValue;
  stateSettings?: ConsultantSettingsInfo;
  deleteFile?: Function;
  remove?: UseFieldArrayRemove;
}

export default withStyles(styles)(SetingsInputView);
