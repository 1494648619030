import React, { ReactElement, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFieldArrayRemove,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  StyleRules,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Image } from 'antd';
import imageCompression from 'browser-image-compression';
import i18n from 'i18next';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

import { DeleteConfirmDialog } from '../../../components/Dialog';
import debug from '../../../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    submit: {
      textAlign: 'center',
    },
    imageGird: {
      position: 'relative',
    },
    imageButton: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    },
    text: {
      margin: 'auto 0',
    },
    borderButton: {
      width: '100%',
      margin: '20px 0',
    },
  });

const WorkInputView = ({
  classes,
  index,
  control,
  errors,
  removeList,
  deleteFile,
  summaryMode = false,
}: Props): ReactElement => {
  debug('Load work input view');
  const { t } = useTranslation();
  i18n.loadNamespaces('ProjectExperienceInputView');
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const { fields: projectImageFileList, append, remove } = useFieldArray({
    control,
    name: `projectInfoList.${index}.projectImageFileList`,
  });

  const handleDelete = async (): Promise<void> => {
    remove(deleteId);
    deleteFile ? deleteFile(true) : '';
    setIsDialogOpen(false);
  };

  const compressFile = async (fileToCompress: File): Promise<string> => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let compressedFile: File;
    try {
      compressedFile = await imageCompression(fileToCompress, options);
      debug(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    } catch (error) {
      debug('Compress file', error);
    }
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result as string);
      reader.readAsDataURL(compressedFile);
    });
  };

  const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    debug('Handle on save', fileObjects);
    for (let index = 0; index < fileObjects.length; index++) {
      const element = fileObjects[index].file;
      const fileToUpload = await compressFile(element);
      append({ file: fileToUpload });
    }
  };

  return (
    <>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Tooltip
                TransitionComponent={Zoom}
                title={!summaryMode ? t('project_experience_input_view:project_name_tooltip') : ''}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus={false}
                  label={t('project_experience_input_view:project_name')}
                  disabled={summaryMode}
                  error={!!errors?.projectInfoList?.[index]?.projectName}
                  helperText={
                    errors?.projectInfoList?.[index]?.projectName &&
                    t('project_experience_input_view:project_name_not_empty')
                  }
                  {...field}
                />
              </Tooltip>
            )}
            name={`projectInfoList.${index}.projectName`}
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <Tooltip
                TransitionComponent={Zoom}
                title={
                  !summaryMode ? t('project_experience_input_view:affiliated_company_tooltip') : ''
                }
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus={false}
                  label={t('project_experience_input_view:affiliated_company')}
                  disabled={summaryMode}
                  error={!!errors?.projectInfoList?.[index]?.projectCompanyName}
                  helperText={
                    errors?.projectInfoList?.[index]?.projectCompanyName &&
                    t('project_experience_input_view:affiliated_company_not_empty')
                  }
                  {...field}
                />
              </Tooltip>
            )}
            name={`projectInfoList.${index}.projectCompanyName`}
            control={control}
            rules={{ required: true }}
          ></Controller>
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={5}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl
                  margin="normal"
                  variant="outlined"
                  error={!!errors?.projectInfoList?.[index]?.projectDateStart}
                  fullWidth
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      variant="inline"
                      format="yyyy-MM"
                      views={['year', 'month']}
                      id="date-picker-start"
                      label={t('project_experience_input_view:start_time')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={summaryMode}
                      readOnly={summaryMode}
                      {...field}
                      value={field.value ?? null}
                    />
                  </MuiPickersUtilsProvider>
                  {errors?.projectInfoList?.[index]?.projectDateStart && (
                    <FormHelperText>
                      {t('project_experience_input_view:start_time_not_empty')}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
            name={`projectInfoList.${index}.projectDateStart`}
            rules={{ required: true }}
            control={control}
          ></Controller>
        </Grid>
        <Grid item xs={5}>
          <Controller
            render={({ field }) => (
              <FormControl margin="normal" variant="outlined" fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    format="yyyy-MM"
                    views={['year', 'month']}
                    id="date-picker-end"
                    label={t('project_experience_input_view:end_time')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={summaryMode}
                    readOnly={summaryMode}
                    {...field}
                    value={field.value ?? null}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
            name={`projectInfoList.${index}.projectDateEnd`}
            control={control}
          ></Controller>
        </Grid>
      </Grid>
      <Controller
        render={({ field }) => (
          <Tooltip
            TransitionComponent={Zoom}
            title={
              !summaryMode ? t('project_experience_input_view:Project_Description_Tooltip') : ''
            }
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus={false}
              multiline
              minRows={4}
              label={t('project_experience_input_view:project_description')}
              disabled={summaryMode}
              error={!!errors?.projectInfoList?.[index]?.projectDescription}
              helperText={
                errors?.projectInfoList?.[index]?.projectDescription &&
                t('project_experience_input_view:project_description_not_empty')
              }
              {...field}
            />
          </Tooltip>
        )}
        name={`projectInfoList.${index}.projectDescription`}
        control={control}
        rules={{ required: true }}
      ></Controller>
      {!summaryMode && (
        <Grid item container xs={12} className={classes.submit}>
          <Grid item xs={7}>
            <Typography
              variant="body2"
              align="left"
              color="secondary"
              className={classes.attachment}
            >
              {t('project_experience_input_view:upload_file_description')}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Tooltip
              TransitionComponent={Zoom}
              title={
                !summaryMode ? t('project_experience_input_view:upload_documents_tooltip') : ''
              }
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsUploadDialogOpen(true);
                }}
              >
                {t('project_experience_input_view:upload_documents')}
              </Button>
            </Tooltip>
          </Grid>
          <DropzoneDialogBase
            fileObjects={[]}
            open={isUploadDialogOpen}
            onAdd={(fileObjects): void => {
              handleOnSave(fileObjects);
            }}
            showPreviews={true}
            maxFileSize={50000000}
            dropzoneText={t('project_experience_input_view:drag_files')}
            dialogTitle={t('project_experience_input_view:uploading')}
            submitButtonText={t('project_experience_input_view:uploading')}
            cancelButtonText={t('project_experience_input_view:cancellation')}
            onClose={(): void => {
              setIsUploadDialogOpen(false);
            }}
          />
        </Grid>
      )}
      {projectImageFileList.map((file, indexImg) => {
        return (
          <Grid key={file.id} item xs={12}>
            <Controller
              render={({ field: { value } }) => (
                <>
                  <Image src={value.file} width={300} />
                  <br></br>
                  {!summaryMode && (
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (value.id) {
                          setIsDialogOpen(true);
                          setDeleteId(indexImg);
                        } else {
                          remove(indexImg);
                        }
                      }}
                    >
                      {t('project_experience_input_view:delete_picture')}
                    </Button>
                  )}
                </>
              )}
              name={`projectInfoList.${index}.projectImageFileList.${indexImg}`}
              control={control}
            ></Controller>
          </Grid>
        );
      })}
      {!summaryMode && removeList && (
        <Grid item container xs={12} alignItems="center" className={classes.bottomButton}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                removeList(index);
              }}
            >
              {t('project_experience_input_view:delete')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.borderButton} />
          </Grid>
        </Grid>
      )}

      <DeleteConfirmDialog
        isOpen={isDialogOpen}
        handleDelete={handleDelete}
        handleDialogClose={() => {
          setIsDialogOpen(false);
        }}
      ></DeleteConfirmDialog>
    </>
  );
};

export type ConsultantProjectInfoControl = Control<{
  projectInfoList: ConsultantProjectInfo[];
}>;
export type ConsultantProjectInfoSetValue = UseFormSetValue<{
  projectInfoList: ConsultantProjectInfo[];
}>;
export type ConsultantProjectInfoErrors = FieldErrors<{
  projectInfoList: ConsultantProjectInfo[];
}>;

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  index: number;
  control: ConsultantProjectInfoControl;
  setValue: ConsultantProjectInfoSetValue;
  errors?: ConsultantProjectInfoErrors;
  removeList?: UseFieldArrayRemove;
  verificationId?: number;
  summaryMode?: boolean;
  deleteFile?: Function;
}

export default withStyles(styles)(WorkInputView);
