import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Control, //类型
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  createStyles,
  Divider,
  Grid,
  StyleRules,
  TextField,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
  Zoom,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import i18n from 'i18next';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';

import debug from '../../../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    infoTitle: {
      marginTop: '10px',
    },
    resumeTitle: {
      margin: '1rem 0 0.5rem',
    },
    consultantResume: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    consultantResumeName: {
      marginRight: '1rem',
    },
    uploadBtn: {
      marginTop: '1rem',
    },
    rootImage: {
      zIndex: 9999,
      background: 'red',
    },
  });

const MyIntroduction = ({
  classes,
  control,
  summaryMode = false,
  setValue,
  otherInfo,
}: Props): ReactElement => {
  debug('Load MyIntroduction input view');
  const { t } = useTranslation();
  i18n.loadNamespaces('LanguagesInputView');

  const [currentResumeName, setCurrentResumeName] = useState<string>('');

  const [resumeUrl, setResumeUrl] = useState<string>('');

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const handleOnSave = async (fileObjects: FileObject[]): Promise<void> => {
    setIsUploadDialogOpen(false);
    debug('Handle on save', fileObjects);
    if (fileObjects.length == 1) {
      const { data, file } = fileObjects[0];
      const url = `name:${file.name};${data}`;
      setCurrentResumeName(file.name);
      setResumeUrl(url);
      setValue?.('otherInfo.resume', url as string, {
        shouldDirty: true,
      });
    }
  };
  //将完整的base64转换为blob
  const dataURLtoBlob = (dataUrl: string) => {
    const arr = dataUrl.split(',') || [];
    const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleDownload = useCallback(() => {
    const url = `${resumeUrl.split(';')?.[1]},${resumeUrl.split(';')?.[2]}` || '';
    const blob = dataURLtoBlob(url);
    const a = document.createElement('a');
    a.download = currentResumeName;
    a.style.display = 'none';
    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }, [resumeUrl, currentResumeName]);

  const formatResume = (el: string) => {
    if (!el) return null;
    const name = `${el.split(';')[0].split(':')[1]}`;
    return {
      name,
      url: `name:${name};${el.split(';')[1]},${el.split(';')[2]}`,
    };
  };

  useEffect(() => {
    if (otherInfo?.resume) {
      setValue?.('otherInfo.resume', otherInfo.resume);
      const data = formatResume(otherInfo.resume);
      if (data) {
        setCurrentResumeName(data.name);
        setResumeUrl(data.url);
      }
    }
  }, [otherInfo]);

  return (
    <>
      <Grid container item xs={12}>
        <Typography align="left" variant="subtitle1" className={classes.infoTitle}>
          自我介绍
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Controller
          render={({ field }) => (
            <Tooltip
              TransitionComponent={Zoom}
              title={!summaryMode ? t('my_introduction:personal_description_tooltip') : ''}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                disabled={summaryMode}
                id="strength"
                label={t('my_introduction:my_introduction_strength')}
                {...field}
              />
            </Tooltip>
          )}
          name="otherInfo.strength"
          control={control}
        ></Controller>
        <Controller
          render={({ field }) => (
            <Tooltip
              TransitionComponent={Zoom}
              title={!summaryMode ? t('my_introduction:personal_description_tooltip') : ''}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                disabled={summaryMode}
                id="background"
                label={t('my_introduction:my_introduction_background')}
                {...field}
              />
            </Tooltip>
          )}
          name="otherInfo.background"
          control={control}
        ></Controller>
        <Controller
          render={({ field }) => (
            <Tooltip
              TransitionComponent={Zoom}
              title={!summaryMode ? t('my_introduction:personal_description_tooltip') : ''}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                disabled={summaryMode}
                id="description"
                label={t('my_introduction:my_introduction_description')}
                {...field}
              />
            </Tooltip>
          )}
          name="otherInfo.description"
          control={control}
        ></Controller>{' '}
      </Grid>
      <Grid item container xs={12} className={classes.submit}>
        {(!summaryMode || otherInfo?.resume) && (
          <>
            <Grid container item xs={12}>
              <Typography align="left" className={classes.resumeTitle}>
                {t('settings_input_view:attachment_resume')}
              </Typography>
            </Grid>
            <Divider
              style={{
                width: '100%',
              }}
            />
          </>
        )}
        {!currentResumeName && !summaryMode && (
          <Tooltip title={t('settings_input_view:upload_resume_tooltip')}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.uploadBtn}
              onClick={() => setIsUploadDialogOpen(true)}
            >
              {t('settings_input_view:upload_resume')}
            </Button>
          </Tooltip>
        )}
        {currentResumeName ? (
          <Grid item xs={12}>
            <Controller
              render={() => (
                <div className={classes.consultantResume}>
                  <span className={classes.consultantResumeName}>{currentResumeName}</span>
                  <span>
                    <GetAppIcon
                      style={{
                        cursor: 'pointer',
                        marginRight: '0.5rem',
                      }}
                      onClick={() => handleDownload()}
                    />
                    {!summaryMode && (
                      <DeleteIcon
                        onClick={() => {
                          setCurrentResumeName('');
                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  </span>
                </div>
              )}
              name={`otherInfo.resume`}
              control={control}
            ></Controller>
          </Grid>
        ) : null}
        <DropzoneDialogBase
          fileObjects={[]}
          acceptedFiles={['.pdf,.doc,.docx']}
          open={isUploadDialogOpen}
          onAdd={(fileObjects): void => {
            handleOnSave(fileObjects);
          }}
          showPreviews={true}
          maxFileSize={50000000}
          dropzoneText={t('personal_info_input_view:drag_files')}
          dialogTitle={t('personal_info_input_view:uploading')}
          submitButtonText={t('personal_info_input_view:uploading')}
          cancelButtonText={t('personal_info_input_view:cancellation')}
          onClose={(): void => {
            setIsUploadDialogOpen(false);
          }}
        />
      </Grid>
    </>
  );
};

// 导出类型
export type ConsultantLanguageInfoControl = Control<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
  otherInfo: ConsultantOtherInfo;
}>;

export type ConsultantLanguageInfoSetValue = UseFormSetValue<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
}>;

export type ConsultantLanguageInfoErrors = FieldErrors<{
  languageInfoList: ConsultantLanguageInfo[];
  personalHonorList: ConsultantPersonalHonorInfo[];
  consultantCertificateList: ConsultantCertificateInfo[];
  otherInfo: ConsultantOtherInfo;
}>;

export type ConsultantOtherInfoSetValue = UseFormSetValue<{ otherInfo: ConsultantOtherInfo }>;
export interface Props extends WithStyles<typeof styles> {
  className?: string;
  control: ConsultantLanguageInfoControl;
  errors?: ConsultantLanguageInfoErrors;
  summaryMode?: boolean;
  basicInfo?: ConsultantBasicInfo;
  otherInfo?: ConsultantOtherInfo;
  setValue?: ConsultantOtherInfoSetValue;
}

export default withStyles(styles)(MyIntroduction);
