import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useForm, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  createStyles,
  Divider,
  Paper,
  StyleRules,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import i18n from 'i18next';

import { activeConsultantVerificationStep } from '../../../reducers/otherStatus';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    formContainer: {
      width: '100%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
    infoTitle: {
      margin: theme.spacing(3),
    },
    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  });
const SuccessPrompt = ({ classes }: Props): ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('SuccessPrompt');
  const dispatch = useDispatch();
  const [count, setCount] = useState(15);

  const transform = () => {
    dispatch(
      activeConsultantVerificationStep({
        ConsultantVerificationStep: 'NONE',
      })
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        transform();
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  return (
    <>
      <Paper className={classes.formContainer}>
        <div className={classes.infoTitle}>
          <Typography component="h1" variant="h5">
            &nbsp;&nbsp; {t('success_prompt:title_msg_h5')}
          </Typography>
          <Divider></Divider>
          <br></br>
          <Typography component="h1" variant="h6">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {t('success_prompt:title_msg_h6')}
          </Typography>
          <br></br>
          <Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t('success_prompt:content')}
          </Typography>
        </div>
        <div className={classes.bottomButton}>
          <Typography>
            {' '}
            {t('success_prompt:countdown')}
            {count} {t('success_prompt:return')}
          </Typography>
          <br></br>
          <br></br>
          <Button variant="contained" color="primary" onClick={transform}>
            {t('success_prompt:homepage')}
          </Button>
        </div>
      </Paper>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(SuccessPrompt);
