import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  createStyles,
  Grid,
  StyleRules,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { getConsultantVerification } from '../../../actions/consultant/consultantVerificationActions';
import { AppState } from '../../../reducers';
import LanguageView from './LanguageSelectView';
import PersonalInfoView from './PersonalInfoView';
import SetView from './SetingsView';
import SuccessPrompt from './SuccessPromptView';
import SummaryView from './SummaryView';
import WorkView from './WorkView';
const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },
    form: {
      width: '100%',
    },
  });
const VerificationView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const consultant = useSelector((appState: AppState) => appState.consultant);
  const consultantVerificationStep = useSelector(
    (appState: AppState) => appState.consultantVerificationStep
  );
  const [consultantType, setConsultantType] = useState<string>(
    consultant.verification?.consultantType || 'PERSONAL'
  );
  setConsultantType;

  useEffect(() => {
    if (consultant && consultant.id) dispatch(getConsultantVerification(consultant.id));
  }, [consultant.id]);

  return (
    <Container className={classes.root} component="main" maxWidth="lg">
      <Grid container>
        <form className={classes.form}>
          {consultantType === 'PERSONAL' &&
            consultantVerificationStep.ConsultantVerificationStep == 'NONE' && <PersonalInfoView />}
          {consultantType === 'PERSONAL' &&
            consultantVerificationStep.ConsultantVerificationStep == 'PERSONAL_STEP' && (
              <WorkView />
            )}
          {consultantType === 'PERSONAL' &&
            consultantVerificationStep.ConsultantVerificationStep == 'PROJECT_STEP' && (
              <LanguageView />
            )}
          {consultantType === 'PERSONAL' &&
            consultantVerificationStep.ConsultantVerificationStep == 'CAPACITY_STEP' && <SetView />}
          {consultantType === 'PERSONAL' &&
            consultantVerificationStep.ConsultantVerificationStep == 'SETTINGS_STEP' && (
              <SummaryView consultant={consultant} />
            )}
          {consultantType === 'PERSONAL' &&
            consultantVerificationStep.ConsultantVerificationStep == 'SUMMARY_STEP' && (
              <SuccessPrompt></SuccessPrompt>
            )}
          {consultantType === 'COMPANY' && <PersonalInfoView />}
        </form>
      </Grid>
    </Container>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  inputEmail?: string | undefined;
  inputSecret?: string | undefined;
  inputAuth?: string | undefined;
}

export default withStyles(styles)(VerificationView);
